import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'tableBodyReplace',
    'filterSectionReplace',
    'searchField',
    'sortByField',
    'filtersField'
  ];

  connect() {}

  sendRequest({ params }) {
    fetch(params.url, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    }).then((response) => response.json());
  }

  applyFilters({ params }) {
    if (!params.sortBy) {
      params.sortBy = this.sortByFieldTarget.dataset.value;
    } else if (!params.filters) {
      params.filters = this.filtersFieldTarget.dataset.value;
    }

    let request_url =
      params.url +
      "?filters=" +
      (params.filters || "") +
      "&sort_by=" +
      (params.sortBy || "") +
      "&index=" +
      (params.index || "") +
      "&turbo_frame=" +
      (params.turboFrame || "");

    this.filtersApi(request_url);
  }

  filtersApi(request_url) {
    fetch(request_url, {
      method: "get",
    })
      .then((response) => response.text())
      .then((htmlResponse) => {
        // conversion from String to HTML
        var parser = new DOMParser();
        var doc = parser.parseFromString(htmlResponse, "text/html");
        var bodyResult = doc.querySelector(".body-to-be-replaced");
        var filterSectionResult = doc.querySelector(".filter-section-to-be-replaced");

        if (bodyResult) {
          this.tableBodyReplaceTarget.replaceWith(bodyResult);
        }
        if (filterSectionResult) {
          this.filterSectionReplaceTarget.replaceWith(filterSectionResult);
        }
      });
  }
}
