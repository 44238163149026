import { Controller } from "@hotwired/stimulus";
import { i18n } from "../components/locales_handler"

export default class extends Controller {

  // Targets used in the Stimulus controller for DOM manipulation.
  static targets = [
    "target", "template", "hiddenConditionField",
    "statusToggleBtn", "stausesMenu", "status",
    "operatorsToggleBtn", "hiddenOperatorField", "operatorsMenu", "operator"
  ];

  // Default values for the controller.
  static values = {
    /**
     * Selector for the wrapper element.
     * @type {String}
     */
    wrapperSelector: { type: String, default: ".nested-form-wrapper" },
    previousStatusDataType:{ type: String, default: "" },
    currentStatusDataType: { type: String, default: "" }
  };

  // Mapping of condition types to their respective data types.
  conditionTypeMap = {
    product_title: "string",
    product_price: "integer",
    product_category: "string",
    product_variant_title: "string",
    product_available_stock: "integer",
    product_meta_keywords:"string",
    product_meta_title: "string",
    product_vat: "integer",
    product_vendor: "string",
    product_discount: "integer"
  };

  // Mapping of data types to their respective operators.
  operatorMap = {
    string: ["contains", "end_with", "start_with", "is", "is_not"],
    integer: ["is_equal", "is_less_than", "is_greater_than", "is_not_equal"]
  };

  initialize() {
    this.closeDropdownOnClickOutside();
    this.operatorsList = [];
  }

  connect() {
    this.setSelectedConditionFromDatabase();
  }

  // Sets the selected condition and operator from the database values on page load.
  setSelectedConditionFromDatabase() {
    const collectionConditionsTable = document.querySelector("table.collection-condition-fields");

    if (collectionConditionsTable) {
      const collectionConditionsTableRows = collectionConditionsTable.querySelectorAll("tbody tr");

      collectionConditionsTableRows.forEach(row => {
        this.handleCollectionConditionRow(row, collectionConditionsTableRows.length)
      });
    }
  }

  handleCollectionConditionRow (row, rowLength) {
    const statusToggleBtn = row.querySelector(".statuses-toggle-btn");
    const operatorsToggleBtn = row.querySelector(".operators-toggle-btn");
    const operatorHiddenField = row.querySelector("#operator-collection-field");
    const statusHiddenField = row.querySelector("#status-collection-field");

    const statusDataType = statusHiddenField.value !== "" ? this.conditionTypeMap[statusHiddenField.value] : this.conditionTypeMap.product_title;

    Object.assign(this, { currentStatusDataType: statusDataType, previousStatusDataType: statusDataType });

    const currentOperatorsList = operatorsToggleBtn.parentElement.querySelectorAll(".operators-menu li");
    const statusesMenu = row.querySelector(".statuses-menu");
    const operatorsMenu = row.querySelector(".operators-menu");
    const statusList = row.querySelectorAll('[data-collection_conditions-target="status"]');

    if (rowLength === 1 && !this.conditionTypeMap[statusHiddenField.value]) {
      this.updateOperatorsList(currentOperatorsList[0].parentElement, statusDataType);
      this.setDefaultStatusAndOperator(statusToggleBtn, statusHiddenField, operatorsToggleBtn, operatorHiddenField, statusesMenu, operatorsMenu )
    } else {
      this.updateDropdownTextAndActivateOption(statusToggleBtn, statusList, statusHiddenField);
      this.updateOperatorsList(currentOperatorsList[0].parentElement, statusDataType);
      this.updateDropdownTextAndActivateOption(operatorsToggleBtn, this.operatorsList.querySelectorAll("li"), operatorHiddenField);
    }
  }

  setDefaultStatusAndOperator (statusToggleButton, statushiddenField, operatorToggleButton, operatorhiddenField, statusesMenu, operatorsMenu ) {
    const defaultOperatorsList = this.operatorMap["string"];
    const defaultStatus = Object.keys(this.conditionTypeMap)[0]
    const operatorText = this.toTitleCase(defaultOperatorsList[0].split("_").join(" "));
    const statusText = this.toTitleCase(defaultStatus.split("_").join(" "));

    statusToggleButton.textContent = statusText;
    statushiddenField.value = defaultStatus;
    operatorToggleButton.textContent = operatorText;
    operatorhiddenField.value = defaultOperatorsList[0];

    const defaultStatusLiElement = statusesMenu.firstElementChild
    const defaultOperatorLiElement = operatorsMenu.firstElementChild

    this.manageActiveClass(defaultStatusLiElement, statusesMenu);
    this.manageActiveClass(defaultOperatorLiElement, operatorsMenu);
  }

  /**
   * Sets the display text of the toggle button to the selected option's text and marks it as active.
   * @param {HTMLButtonElement} toggleBtn - The button that toggles the dropdown.
   * @param {NodeList} list - The list of options in the dropdown.
   * @param {string} hiddenField - The value of the hidden field that stores the selected option.
   */
  updateDropdownTextAndActivateOption(toggleBtn, list, hiddenField) {
    const selectedOption = Array.from(list).find(option => option.dataset.value === hiddenField.value);

    if (selectedOption) {
      toggleBtn.textContent = selectedOption.querySelector("span").textContent;
      selectedOption.classList.add("active");
    }
  }

  handleConditionValue(event) {
    const inputValue = event.target.value.trim();
    const isNumeric = /^\d+$/.test(inputValue);

    if (this.currentStatusDataType === 'integer' && inputValue) {
      isNumeric ? this.removeConditionFieldValueError(event.target) : this.generateConditionFieldValueError(event.target);
    } else {
      this.removeConditionFieldValueError(event.target);
    }
  }

  removeConditionFieldValueError(field) {
    const errorClass = "condition-field-value-error";
    const existingError = field.parentNode.querySelector(`.${errorClass}`);

    if (existingError) {
      existingError.remove();
      field.classList.remove("border-red-500");
    }
  }

  generateConditionFieldValueError (field) {
    const errorClass = "condition-field-value-error";
    const existingError = field.parentNode.querySelector(`.${errorClass}`);

    if (!existingError) {
      const errorField = document.createElement("div");
      errorField.classList.add(errorClass, "text-red-500");
      errorField.textContent = i18n.t('please_enter_valid_input');
      field.classList.add("border-red-500");
      field.parentNode.appendChild(errorField);
    }
  }

  /**
   * Event handler for toggling the statuses or operators menu.
   * @param {Event} event - The event object from the click handler.
   */
  toggleStatusesOrOperatorsMenu(event) {
    event.stopPropagation();

    const dropdownType = this.isDropdownStatusType(event.params.dropdowntype);
    const stausesMenu = event.currentTarget.querySelector("[data-collection_conditions-target=stausesMenu]");
    const operatorsMenu = event.currentTarget.querySelector("[data-collection_conditions-target=operatorsMenu]");

    if (dropdownType) {
      this.toggleDropdownMenuVisibility(stausesMenu);
    } else {
      this.toggleDropdownMenuVisibility(operatorsMenu);
    }
  }

  /**
   * Displays or hides the specified dropdown menu based on its current visibility.
   * @param {HTMLElement} menuType - The dropdown menu to be shown or hidden.
   */
  toggleDropdownMenuVisibility(menuType) {
    if (menuType.classList.contains("hidden")) {
      this.hideAllDropdowns();
      this.show(menuType);
    } else {
      this.hide(menuType);
    }
  }

  /**
   * Checks if the dropdown type is 'condition'.
   * @param {string} type - The type of the dropdown.
   * @returns {boolean} - Returns true if the type is 'condition', false otherwise.
   */
  isDropdownStatusType = type => type === "status";

  /**
   * Removes the 'hidden' class from the menu target to show it.
   * @param {HTMLElement} menuTarget - The target menu to show.
   */
  show(menuTarget) {
    menuTarget.classList.remove("hidden");
  }

  /**
   * Adds the 'hidden' class to the menu target to hide it.
   * @param {HTMLElement} menuTarget - The target menu to hide.
   */
  hide(menuTarget) {
    menuTarget.classList.add("hidden");
  }

  /**
   * Hides all dropdown menus by adding the 'hidden' class.
   */
  hideAllDropdowns() {
    document.querySelectorAll(".collection-condition-menu").forEach(dropdown => {
      dropdown.classList.add("hidden");
    });
  }

  /**
   * Handles the selection of a condition or operator from the dropdown menu.
   * It stops the event propagation, determines the dropdown type, updates the operators list if necessary,
   * and updates the selected option in the UI.
   *
   * @param {Event} event - The event triggered by selecting an option.
   */
  selectedStatusOrOperatorHandler(event) {
    event.stopPropagation();

    let statusDataType;

    const dropdownType = this.isDropdownStatusType(event.params.dropdowntype); // return true if clicked dropdown is status else galse
    const currentConditionRow = event.currentTarget.closest("tr")

    const statusToggleBtn = currentConditionRow.querySelector(".statuses-toggle-btn")
    const operatorsToggleBtn = currentConditionRow.querySelector(".operators-toggle-btn")

    const statusHiddenFieldTarget = currentConditionRow.querySelector("#status-collection-field")
    const operatorHiddenFieldTarget = currentConditionRow.querySelector("#operator-collection-field")

    const collectionConditionFieldValue = currentConditionRow.querySelector(".condition-field-value")

    const statusesMenu = event.currentTarget.closest("tr").querySelector(".statuses-menu")
    const operatorsMenu = event.currentTarget.closest("tr").querySelector(".operators-menu")

    const selectedLiElement = event.target.parentElement;
    const previouslySelectedValue = statusToggleBtn.textContent.trim().toLowerCase().replace(/\s+/g, '_');

    this.previousStatusDataType = this.conditionTypeMap[previouslySelectedValue]

    if (dropdownType) {

      statusDataType = this.conditionTypeMap[selectedLiElement.dataset.value];

      this.updateOperatorsList(operatorsMenu, statusDataType);
      this.updateSelectedOption(statusHiddenFieldTarget, statusToggleBtn, statusesMenu, selectedLiElement, dropdownType, statusDataType);

      // Reset the operator text content to "Select an Option" and reset operatorHiddenFieldTarget value if the dropdown type is status
      // and status datatype changes from string to integer or from integer to string
      if (this.previousStatusDataType !== statusDataType ) { 
        collectionConditionFieldValue.value = "";
        this.resetOperatorSelection(operatorsToggleBtn, operatorHiddenFieldTarget, statusDataType, operatorsMenu, collectionConditionFieldValue);
      }
    } else {
      //this iterations sets the status datatype by matching selected operator value
      for (const key in this.operatorMap) {
        if (this.operatorMap[key].includes(selectedLiElement.dataset.value)) {
          statusDataType =  key;
          break;
        }
      }
      this.updateSelectedOption(operatorHiddenFieldTarget, operatorsToggleBtn, operatorsMenu, selectedLiElement, dropdownType, statusDataType);
    }
    this.previousStatusDataType = statusDataType;
  }

  /**
   * Updates the operators list in the UI based on the selected condition's data type.
   *
   * @param {HTMLElement} operatorsList - The HTML element that contains the list of operators.
   * @param {string} statusDataType - The data type of the selected condition which determines the operators to be displayed.
   */
  updateOperatorsList(operatorsList, statusDataType) {
    const newOperatorsList = this.operatorMap[statusDataType];
    operatorsList.innerHTML = '';

    newOperatorsList.forEach((operator) => {
      const liElement = document.createElement('li');
      liElement.className = 'operator-option group relative py-1.5 px-2.5 styled-collection-option cursor-pointer';
      liElement.setAttribute('data-collection_conditions-target', 'operator');
      liElement.setAttribute('data-value', operator);

      const operatorTextContent = operator.split("_").join(" ");
      const operatorTitleCase = this.toTitleCase(operatorTextContent);
      const spanElement = document.createElement('span');
      spanElement.className = 'inline-block p-1.5 w-full rounded group-hover:bg-blue-50';
      spanElement.textContent = operatorTitleCase;

      liElement.appendChild(spanElement);
      operatorsList.appendChild(liElement);
    });
    this.operatorsList = operatorsList;
  }

  /**
   * Converts a string to title case.
   *
   * @param {string} str - The string to be converted to title case.
   * @returns {string} - The string converted to title case.
   */
  toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  /**
   * Updates the selected condition/operator text based on the provided elements and dropdown type.
   *
   * @param {HTMLInputElement} hiddenField The hidden input field containing the data-value for the selected condition/operator.
   * @param {HTMLButtonElement} menuToggleButton The button element displaying the selected condition/operator text.
   * @param {HTMLUListElement} menuTarget The unordered list element containing the condition/operator options.
   * @param {HTMLLIElement} selectedLiElement The list item element representing the selected condition/operator.
   * @param {boolean} dropdownType Indicates whether the type is "condition" (true) or "operator" (false).
  */
  updateSelectedOption(hiddenField, menuToggleButton, menuTarget, selectedLiElement, dropdownType, statusDataType) {
    //assign selected value to hidden field
    hiddenField.value = selectedLiElement.dataset.value;

    //change visible text to selected condition/operator text
    menuToggleButton.textContent = selectedLiElement.querySelector("span").textContent;
    this.currentStatusDataType = statusDataType
    //close the menu
    this.hide(menuTarget)

    // Update the active state of the selected option and deactivate the previously active option if different
    this.manageActiveClass(selectedLiElement, menuTarget);
  }

  manageActiveClass(selectedLiElement, menuTarget) {
    // Find the previously active option
    const isActiveSelectedOption = menuTarget.querySelector("li.active");

    // Update the active state of the selected option and deactivate the previously active option if different
    if (isActiveSelectedOption && isActiveSelectedOption !== selectedLiElement) {
      isActiveSelectedOption.classList.remove("active");
    }

    // Add active class to the selected option
    selectedLiElement.classList.add("active");
  }

  resetOperatorSelection (menuToggleButton, hiddenField, statusDataType, operatorsMenu, collectionConditionFieldValue ) {
    const newOperatorsList = this.operatorMap[statusDataType];
    const operatorTextContent = newOperatorsList[0].split("_").join(" ");
    const operatorTitleCase = this.toTitleCase(operatorTextContent);
    menuToggleButton.textContent = operatorTitleCase;
    hiddenField.value = newOperatorsList[0];

    this.manageActiveClass(this.operatorsList.firstChild, operatorsMenu)
  };

  // Closes all dropdowns when a click occurs outside of the dropdown menu.
  closeDropdownOnClickOutside() {
    /**
     * Closes the dropdown menu if the click event target is not within a dropdown menu.
     * @param {MouseEvent} event - The mouse event triggered by clicking.
     */
    const closeMenu = (event) => {
      if (!event.target.closest(".collection-condition-menu")) {
        this.hideAllDropdowns();
      }
      document.removeEventListener('click', closeMenu);
    };
    document.addEventListener('click', (event) => closeMenu(event));
  }

  /**
   * Prevents the default action and adds a new record to the DOM.
   * The new record's content is derived from a template, replacing a placeholder with a unique timestamp.
   *
   * @param {Event} event - The event object associated with the action.
   */
  add(event) {
    event.preventDefault();

    // Replace placeholder in template with a unique timestamp and insert into DOM
    const content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime().toString()
    );
    this.targetTarget.insertAdjacentHTML("beforebegin", content);
  }

  /**
   * Prevents the default action and removes a record from the DOM.
   * If the record is new, it removes the record and its associated fields.
   * If the record is not new, it hides the record and sets a flag for destruction.
   *
   * @param {Event} event - The event object associated with the action.
   */
  remove(event) {
    event.preventDefault();

    // Find the closest wrapper element based on a selector value
    const wrapper = event.target.closest(this.wrapperSelectorValue);

    // Check if the record is new and handle accordingly
    if (wrapper.dataset.newRecord === "true") {
      const wrapperParent = $(wrapper).closest('.collection-condition-fields');
      $(wrapperParent).prev('.fields').remove();
      $(wrapperParent).remove();
    } else {
      // Hide the wrapper and set a destruction flag on a hidden input field
      wrapper.style.display = "none";
      const input = wrapper.querySelector("input[name*='_destroy']");
      input.value = "1";
    }
  }
}
