
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    if (this.element.dataset.variantsCount > 1) {
      this.element.classList.add('hidden')
    }
  }
}
