import { Controller } from "@hotwired/stimulus"
import $ from "jquery"
import OrderItemsController from "./order_items_controller";

export default class extends Controller {
  static targets = ["productImageInput", "selectVariantImage"];
  static openVariantImagesModelFlag = false;

  connect() {}

  uploadProductAttachments(event) {
    const request_url = event.params.url;
    const input = this.productImageInputTarget;

    if (input.files && input.files.length > 0) {
      const formData = new FormData();

      // Calling Loop through each file and append it to the FormData
      for (let i = 0; i < input.files.length; i++) {
        formData.append('attachments[]', input.files[i]);
      }
      $('.file-upload-input').prop( "disabled", true );
      $('.spinner-loader').removeClass("hidden");
      $('.add-image-text').css("display",'none');
      fetch(request_url, {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
        }
      })
      .then(response => response.json())
      .then(data => {
        // Check if the response contains a message
        this.clearLoadingContainer();
        let element = document.querySelector(".main-flash-container");
        if (data && data.message) {
          // Handle success message
          let status = true
          element.innerHTML +=
          this.custom_flash_message().prototype.custom_flash_message(
            data.message.toString(),
            element,
            status
          );
        } else if (data && data.error) {
          // Handle error message
          let status = false
          element.innerHTML += this.custom_flash_message().prototype.custom_flash_message(
            data.error.toString(),
            element,
            status
          );
        }
      })
      .catch(error => {
        console.error('Error:', error);
        this.clearLoadingContainer();
      });
    }
  }

  clearLoadingContainer(){
    $('#reload-list').load(window.location.href + ' #reload-list > *');
    $('#attachments').load(window.location.href + ' #attachments > *');
    $('.add-image-text').css("display",'block');
    $('.btn-loader').css('display','none');
  }

  async openVariantImage(event) {
    if(Controller.openVariantImagesModelFlag) return ;

    Controller.openVariantImagesModelFlag = true;
    
    await new Promise(resolve => {
      $('#reload-list').load(window.location.href + ' #reload-list > *', resolve);
    });

    const listId = $(event.target).closest('.nested-variant-forms').data('list-id')
    const variantId = $(event.target).closest('.nested-variant-forms').find('.variant_id').val();
    const toggleVariantModal = document.querySelector('.toggle-image-variant-modal');
    toggleVariantModal.click();
    const editableVariantModal = document.querySelector('.save-variant-image');
    $(editableVariantModal).attr('data-list-id', listId)
    $(editableVariantModal).attr('data-variant-id', variantId)
    $(event.target).attr('data-list-id', listId)

    const variantImages = $('.product-add-image');
    const selectedImage = Array.from(variantImages).find(imageElement => imageElement.currentSrc === event.target.src);
    if (selectedImage) {
      const isChecked = $(selectedImage).next();
      if (isChecked && isChecked.is('input')) {
        isChecked.prop('checked', true);
      } else {
        console.log("No valid next sibling found.");
      }
    } else {
      console.log("No match found.");
    }
    Controller.openVariantImagesModelFlag = false;
  }

  handleSelectedVariantImage(event) {
    const checkbox = event.currentTarget.querySelector('input[type=checkbox]');
    const isChecked = checkbox.checked;

    // Remove selected-image class and Uncheck all checkboxes
    this.selectVariantImageTargets.forEach((otherCheckbox) => {
      const otherCheckboxInput = otherCheckbox.querySelector('input[type=checkbox]');
      otherCheckboxInput.checked = false;
      otherCheckbox.classList.remove("selected-image");
    });

    // If the clicked image wasn't selected, mark it as selected
    if (!isChecked) {
      event.currentTarget.classList.add("selected-image");
      checkbox.checked = true;
    }
  }

  addSelectedImageToVariant(event){
    const request_url = event.params.url
    const variantId = event.target.dataset.variantId
    const avatarId = $('.selected-image').attr('id')
      const authenticityToken = $('meta[name="csrf-token"]').attr('content');
      const controller = this

      $.ajax({
        url: request_url,
        type: 'PATCH',
        data: { avatar_id: avatarId, variant_id: variantId, authenticity_token: authenticityToken },
        success: function(response) {
          let element = document.querySelector(".main-flash-container");
          if (response.status == 200) {
            let status = true
            element.innerHTML += this.custom_flash_message().prototype.custom_flash_message(
              response.message.toString(),
              element,
              status
            );
            let variantsData = response.variants;
            const variantListSectionController = this.application.getControllerForElementAndIdentifier(
              document.querySelector('[data-controller="variant-list-section"]'),
              'variant-list-section'
              );
            variantsData = variantListSectionController.mapVariantDataForVariantList(variantsData)
            variantListSectionController.variantsList = variantsData
            variantListSectionController.variantListManagement();
            variantListSectionController.closeModalAndReturnToOriginalPosition();
            $('.close-variant-image-upload-modal').trigger('click');
          }
          else {
            let status = false
            element.innerHTML += this.custom_flash_message().prototype.custom_flash_message(
              response.error.toString(),
              element,
              status
              );
            }
          }.bind(controller),
          error: function(xhr, status, error) {console.error("Error updating avatar:", error);
        }
      });
    }

  custom_flash_message() {
    return OrderItemsController;
  }
}
