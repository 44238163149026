import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["color", "output", "pickerOutput"];

  connect() {
    this.outputTarget.value = `${this.colorTarget.value}`;
  }

  color_change_listener() {
    this.outputTarget.value = `${this.colorTarget.value}`;
  }

  red() {
    this.outputTarget.value = "#F44158";
    this.pickerOutputTarget.value = "#F44158";
  }

  yellow() {
    this.outputTarget.value = "#ffff00";
    this.pickerOutputTarget.value = "#ffff00";
  }

  black() {
    this.outputTarget.value = "#000000";
    this.pickerOutputTarget.value = "#000000";
  }

  white() {
    this.outputTarget.value = "#ffffff";
    this.pickerOutputTarget.value = "#ffffff";
  }
}
