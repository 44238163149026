import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['previousBtn', 'nextBtn'];

  connect() {
    this.pageCount()
    this.previousPage(null, true)
    this.nextPage(null, true)
  }

  pageCount() {
    const pageCountElement = document.querySelector("[data-page-count]")
    const pageCount = parseInt(pageCountElement.dataset.pageCount)

    if (pageCount === 1) {
      this.disableButtons()
    }
  }

  nextPage(event, checkOnly = false) {
    this.handlePageNavigation(event, '/admin/next_page', checkOnly)
  }

  previousPage(event, checkOnly = false) {
    this.handlePageNavigation(event, '/admin/previous_page', checkOnly)
  }

  handlePageNavigation(event, endpoint, checkOnly = false) {
    if (event) {
      event.preventDefault();
    }

    const pageId = document.querySelector('div[data-record-id]').getAttribute('data-record-id')

    fetch(`${endpoint}?page_id=${pageId}`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        if (data.message && data.page_id) {
          if (!checkOnly) {
            window.location.href = `/admin/resources/pages/${data.page_id}/edit`
          }
        } else {
          this.disableButton(endpoint)
        }
      })
      .catch((error) => {
        this.disableButton(endpoint)
      });
  }

  disableButton(endpoint) {
    const button = endpoint.includes('next_page') ? this.nextBtnTarget : this.previousBtnTarget
    button.classList.add("pointer-events-none", "opacity-50", "cursor-not-allowed")
  }

  disableButtons() {
    this.previousBtnTarget.classList.add("pointer-events-none", "opacity-50", "cursor-not-allowed")
    this.nextBtnTarget.classList.add("pointer-events-none", "opacity-50", "cursor-not-allowed")
  }
}
