import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["productTagInput", "chips", "hiddenField"];

  connect() {
    this.updateHiddenField();
  }

  toggleProductTagInput() {
    this.productTagInputTarget.classList.toggle('block');

    if (this.productTagInputTarget.classList.contains('block')) {
        this.productTagInputTarget.focus();
    }
  }

  addProductTagChip(event) {
    if (event.key === 'Enter') {
      event.preventDefault();

      const chipText = this.productTagInputTarget.value.trim();
      if (!chipText) {
        return;
      }

      const chip = this.createChip(chipText);
      this.chipsTarget.appendChild(chip);
      this.updateHiddenField();

      this.productTagInputTarget.value = "";
    }
  }

  removeProductTagChip(event) {
    if (event.target.classList.contains("chip--button")) {
      event.target.closest(".chip").remove();
      this.updateHiddenField();
    }
  }

  createChip(text) {
    const chip = document.createElement("div");
    chip.classList.add("chip");
    chip.innerHTML = `
      <span class="chip--text">${text}</span>
      <span class="chip--button" data-action="click->catalogue-organization#removeProductTagChip">x</span>
    `;
    return chip;
  }

  updateHiddenField() {
    const chipTexts = Array.from(this.chipsTarget.querySelectorAll(".chip--text")).map(chip => chip.textContent);
    this.hiddenFieldTarget.value = JSON.stringify(chipTexts);
  }
}
