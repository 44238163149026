import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['emailUnique', 'submitBtn'];

  connect() {}

  emailListener(event) {
    let element = event.target;
    let request_url = element.dataset.url + "?email=" + (element.value || '') + "&store_id=" + element.dataset.storeId;

    this.emailUniqueApi(request_url);
  }

  emailUniqueApi(request_url) {
    fetch(request_url, {
      method: "get",
    })
      .then((response) => response.json())
      .then((response) => {
        if(response.exists) {
          this.emailUniqueTarget.classList.remove('hidden')
          this.submitBtnTarget.disabled = true;
        } else {
          this.emailUniqueTarget.classList.add('hidden')
          this.submitBtnTarget.disabled = false;
        }
      });
  }
}
