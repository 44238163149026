
import { Controller } from "@hotwired/stimulus"
import tinymce from 'tinymce';

export default class extends Controller {

  initialize() {
    tinymce.remove();

    tinymce.init({
      selector: 'textarea.tinymce',
      promotion: false,
      branding: false,
      menubar: false,
      resize: false,
      content_style: 'body { font-size: 14pt; font-family: Arial }',
      plugins: 'image code lists media fullscreen preview hr link textcolor table',
      toolbar: ['styleselect undo redo | h1 h2 h3 h4 h5 h6 | fontfamily fontsize',
      'blockquote bold italic underline strikethrough hr forecolor backcolor| alignleft aligncenter alignright alignjustify bullist numlist outdent indent | image media removeformat link table | code fullscreen preview help'],
      table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
      image_title: true,
      media_poster: false,
      automatic_uploads: true,
      file_picker_types: 'image',
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.onchange = function () {
          var file = this.files[0];

          var reader = new FileReader();
          reader.onload = function () {
            var id = 'blobid' + (new Date()).getTime();
            var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
            var base64 = reader.result.split(',')[1];
            var blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);

            cb(blobInfo.blobUri(), { title: file.name });
          };
          reader.readAsDataURL(file);
        };

        input.click();
      },
      setup: (editor) => {
        const handleContentChange = () => {
          const currentContent = editor.getContent();
          document.dispatchEvent(new CustomEvent('tinymce:contentChange', { detail: { content: currentContent } }));
        };
      
        editor.on("change input", handleContentChange);
      },   
    });
  }

  connect() {
    this.initialize();
  }
}
