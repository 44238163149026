import { application } from "./application";
import Carousel from 'stimulus-carousel'

import RibbonColorController from "./ribbon_color_controller";
import MarketPlaceRequestController from "./market_place_request_controller";
import AffiliateInvitationsController from "./affiliate_invitations_request_controller";
import AffiliatedCampaignsController from "./affiliated_campaigns_controller";
import RecentTransactionsController from "./recent_transactions_controller";
import InventoryLevelController from "./inventory_level_controller";
import PaymentsController from "./payments_controller";
import SearchFormController from "./search_form_controller";
import CollectionConditionsController from "./collection_conditions_controller";
import StoreNamesController from "./store_names_controller";
import StoreDomainNameController from "./store_domain_name_controller";
import OrderItemsController from "./order_items_controller";
import AvoCustomInputsController from "./avo_custom_inputs_controller";
import CollectionProductsStatusController from "./collection_products_status_controller";
import AdminUserStoreAccessController from "./admin_user_store_access";
import DragController from "./drag_controller";
import VariantsPositionDraggableController from "./variants_position_draggable_controller";
import StripeController from "./stripe_controller";
import homePagesController from "./home_controller";
import carousel from "bootstrap/js/src/carousel";
import StoreController from "./store_controller";
import IncomingAffiliationRequestsController from "./incoming_affiliation_requests_controller";
import CollectionProductsController from "./collection_products_controller";
import IncomingStockController from "./incoming_stock_controller";
import CustomAlertController from "./custom_alert_controller"
import VariantItemSectionController from "./variant_item_section_controller";
import VariantListSectionController from "./variant_list_section_controller";
import TinymceController from "./tinymce_controller";
import MenuController from "../js/controllers/menu_controller";
import ProductImageUploadController from "./product_image_upload_controller";
import DetectProductChangerController from "./detect_product_changer_controller";
import CustomDropDownController from "./custom_dropdown";
import BrowseAffiliateUsersController from "./browse_affiliate_users_controller"
import FilterDropdownController from "./filter_dropdown_controller";
import StockManagementController from "./stock_management_controller";
import PagesController from "./pages_controller";
import CatalogueOrganizationController from "./catalogue_organization_controller";

application.register("ribbon_color", RibbonColorController);
application.register("market_place_request", MarketPlaceRequestController);
application.register(
  "affiliate_invitations_request",
  AffiliateInvitationsController
);
application.register("affiliated_campaigns", AffiliatedCampaignsController);
application.register("recent_transactions", RecentTransactionsController);
application.register("inventory_level", InventoryLevelController);
application.register("payments", PaymentsController);
application.register("search-form", SearchFormController);
application.register("collection_conditions", CollectionConditionsController);
application.register("store_names", StoreNamesController);
application.register("order_items", OrderItemsController);
application.register("store-domain-name", StoreDomainNameController);
application.register("avo-custom-inputs", AvoCustomInputsController);
application.register("collection-products-status", CollectionProductsStatusController);
application.register("admin-user-store-access", AdminUserStoreAccessController);
application.register("drag", DragController);
application.register("variants_position_draggable", VariantsPositionDraggableController);
application.register("stripe", StripeController);
application.register("home", homePagesController);
application.register("carousel", Carousel);
application.register("store", StoreController);
application.register("incoming_affiliation_requests", IncomingAffiliationRequestsController);
application.register("collection_products", CollectionProductsController);
application.register("incoming-stock", IncomingStockController);
application.register("custom-alert", CustomAlertController);
application.register("variant-item-section", VariantItemSectionController);
application.register("variant-list-section", VariantListSectionController);
application.register("tinymce", TinymceController);
application.register("menu", MenuController);
application.register("product-image-upload", ProductImageUploadController);
application.register("detect-product-changer", DetectProductChangerController);
application.register("custom-dropdown", CustomDropDownController);
application.register("browse_affiliate_users", BrowseAffiliateUsersController);
application.register("filter-dropdown", FilterDropdownController);
application.register("stock-management", StockManagementController);
application.register("pages", PagesController);
application.register("catalogue-organization", CatalogueOrganizationController);
