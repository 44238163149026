import { Controller } from "@hotwired/stimulus";

import jquery from "jquery";

function scrollToDiv(elemId, e = null) {
  const scrollElement = document.getElementById(elemId);

  if (scrollElement) {
    if (e) e.preventDefault();

    scrollElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }
}

export default class extends Controller {
  static targets = ['priceSwitch', 'monthlySection', 'yearlySection'];

  connect() {
    setTimeout(function () {
      window.scroll(0, 0);
    }, 10);

    if (window.location.hash) setTimeout(function () {
      scrollToDiv(window.location.hash.split('#')[1])
    }, 200);
  }

  gotoSection(e) {
    const scrollElementId = e.target.getAttribute('data-id');

    scrollToDiv(scrollElementId, e);
  }

  toggleNavbar() {
    jquery('navbar-sticky').toggleClass('hidden');
  }

  priceSwitchClick(e) {
    jquery(this.priceSwitchTargets).removeClass('active');
    jquery(e.target).addClass('active');
    const switchType = jquery(e.target).attr('data-switch-type');
    const isMonthly = switchType === 'monthly';

    jquery(isMonthly ? this.yearlySectionTarget : this.monthlySectionTarget).addClass('hidden');
    jquery(isMonthly ? this.monthlySectionTarget : this.yearlySectionTarget).removeClass('hidden');
  }
}
