import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ['draggableItem', 'productVariantsList', 'draggableVariantOption', 'draggableVariantOptionValue'];
  connect(){
    // jquery use for get attachments section
    const attachmentImages = $('#attachments').children().last();

    if (attachmentImages[0]) {
      attachmentImages.attr('data-controller', 'variants_position_draggable');
      // drag and drop attachment image
      Sortable.create(attachmentImages[0], {
        animation: 150,
        onEnd: (event) => {},
      })
    }

    this.initializeSortableLists();
    document.addEventListener('initializeSortableLists', this.initializeSortableLists.bind(this))

    this.sortable = Sortable.create(this.element, {
      handle: ".variant-list-handler",
      sort: true,
      onEnd: this.end.bind(this)
    })
  }

  initializeSortableList(innerList, handleSelector, options) {
    Sortable.create(innerList, {
      handle: handleSelector,
      ...options,
    });
  }

  initializeSortableLists() {
    const nestedSortable = this.draggableVariantOptionTarget;
    const nestedSortableOptionValues = this.draggableVariantOptionValueTargets;

    nestedSortableOptionValues.forEach(innerList => {
      this.initializeSortableList(innerList, '.drag-option-value-handler', {
        animation: 250,
        filter: ".exclude", //use this
        onMove: (evt) =>  {
          return evt.related.className.indexOf('exclude') === -1; //and this
        },
        onEnd: () => {
          document.dispatchEvent(new CustomEvent("variantListManagement"));
          document.dispatchEvent(new CustomEvent("detectVariantOptionChanges"));
        },
        group: {
          put: false
        },
        preventSort: '.truly-fixed', // element with this class won't be sortable
        preventOnFilter: false //enable prevent sort input to be clickable after sort
      });
    });

    this.initializeSortableList(nestedSortable, '.drag-option-handler', {
      animation: 150,
      fallbackOnBody: true,
      swapThreshold: 0.65,
      onEnd: (event) => {
        const variantOptionSections = Array.from(document.querySelectorAll(".variant-item-section"));
        variantOptionSections.forEach((option, index) => {
            option.dataset.order = index + 1;
        });
        document.dispatchEvent(new CustomEvent("variantListManagement"));
        document.dispatchEvent(new CustomEvent("detectVariantOptionChanges"));
      }
    });
  }

  end(event) {
    event.preventDefault();
    let updatePosition = (id, position) => {
      let url = '/admin/variants/' + id + '/arrange_positions';
      let body = JSON.stringify({
        position: position
      })

      fetch(url, {
        method: 'PATCH',
        headers: { "Content-Type": "application/json" },
        body: body
      })
        .then((response) => response.json())
        .then((response) => {
          if(response.status == 'ok') {
            this.productVariantsListTarget.innerHTML = response.content;
          }
        });
    }
    this.draggableItemTargets.map(function(t, i) {
      updatePosition(t.id, i + 1)
      return{
        id: t.id, position: i + 1
      }
    })
  }
}
