import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['detectFields', 'stickyBar', "variantItemSection"];

  initialize () {
    this.previousDescriptionContent = '';
    this.previousOptionSectionsCount = 0;
    this.changesDetection = {
      fieldChanges: false,
      descriptionChanges: false,
      publishChanges: false,
      isVariantOptionsChanged: false,
      isVariantsListChanged: false,
      checkHandleChanges: false,
      variantOptionsChanges: {
        added: [],
        removed: [],
        modified: [],
        newOptionsList: [],
        previousOptionsList: [],
      },
      variantListChanges: {
        added: [],
        removed: [],
        modified: [],
        newVariantsList: [],
        previousVariantsList: [],
      },
    };
  }

  // Getter method for previousOptionsList
  get previousOptionsList () {
    return this.changesDetection.variantOptionsChanges.previousOptionsList;
  }

  // Setter for previousOptionsList - updates the previousOptionsList in changesDetection object
  set previousOptionsList (value) {
    this.changesDetection.variantOptionsChanges.previousOptionsList = value;
  }

  // Getter for previousVariantsList - retrieves the previousVariantsList from changesDetection object
  get previousVariantsList () {
    return this.changesDetection.variantListChanges.previousVariantsList;
  }

  // Setter for previousVariantsList - updates the previousVariantsList in changesDetection object
  set previousVariantsList (value) {
    this.changesDetection.variantListChanges.previousVariantsList = value;
  }

  connect () {
    if (this.element.dataset.resourceEditViewValue === "edit") {
      document.addEventListener('tinymce:contentChange', this.handleDescriptionChange.bind(this));
      this.addEvent("select2:changed", this.initPreviousVariantOptionsList.bind(this));
      this.addEvent("detectVariantOptionChanges", this.detectVariantOptionChanges.bind(this));
      this.addEvent("detectChangesInVariantList", this.detectChangesInVariantList.bind(this));

      // Populate the previous value of the description field
      const descriptionTextarea = document.getElementById("product_description1");
      this.previousDescriptionContent = descriptionTextarea.defaultValue;

      // Populate an array with the values of all the fields targeted by detectFields
      this.previousFieldValues = this.detectFieldsTargets.map(field => field.value);

      // Add a listener to each field targeted by detectFields to check for changes in their values
      this.detectFieldsTargets.forEach((field, index) => {
        field.addEventListener('input', event => {
          this.detectFieldChange(index, field.value);
        });
      });
      // do not detect changes until page is loaded
      this.isLoading = true;
      window.addEventListener('load', () => {
        setTimeout(() => {
            this.isLoading = false;
        }, 500);
      });
    }
  }

  // This function checks if any field value has changed by comparing the current and previous values
  // If a change is detected, it updates the fieldChanges property in the changesDetection object and triggers the sticky bar
  detectFieldChange (index, currentFieldValue) {
    const currentFieldValues = this.detectFieldsTargets.map(field => field.value)
    const isChangeDetected = JSON.stringify(currentFieldValues) !== JSON.stringify(this.previousFieldValues)
    this.changesDetection = {...this.changesDetection, fieldChanges: isChangeDetected}
    this.updateStickyBarVisibility()
  }

  inputFieldHandler (event) {
    this.previousFieldValues = this.detectFieldsTargets.map(element => element.value);
    this.detectFieldsTargets.forEach((element, index) => {
      element.addEventListener('input', event => {
        this.checkFieldValueChange(index, element.value);
      });
    });
  }

  // This function handles changes in the description by comparing the current and previous description content.
  // If a change is detected, it updates the descriptionChanges property in the changesDetection object and triggers the sticky bar visibility update.
  handleDescriptionChange (event) {
    const currentDescriptionContent = event.detail.content;
    const isDescriptionChanged = this.previousDescriptionContent !== currentDescriptionContent;
    this.changesDetection = {...this.changesDetection, descriptionChanges: isDescriptionChanged};
    this.updateStickyBarVisibility();
  }

  // This function initializes the previous variant options list, option sections count, and variants list based on the event details.
  initPreviousVariantOptionsList (event) {
    this.previousOptionsList = event.detail.variantOptionsList;
    this.previousOptionSectionsCount = event.detail.variantOptionSectionsCount
    this.previousVariantsList = event.detail.variantsList
  }

  publishCheckHandle (event) {
    this.changesDetection = {...this.changesDetection,publishChanges:event.target.checked}
    this.updateStickyBarVisibility();
  }

  // This function detects changes in variant options by comparing the new and previous variant options lists and their counts.
  // If changes are detected, it updates the changesDetection object and triggers the sticky bar visibility update.
  detectVariantOptionChanges (event) {
    const newVariantOptionsList = event.detail.variantOptionsList;
    const previousVariantOptionsList = this.previousOptionsList;

    const newVariantOptionsListCount =  event.detail.variantOptionSectionsCount;
    const previousVariantOptionsListCount = this.previousOptionSectionsCount;

    const { hasChanges } = this.compareOptionLists(previousVariantOptionsList, newVariantOptionsList, newVariantOptionsListCount, previousVariantOptionsListCount);
    this.changesDetection = { ...this.changesDetection , isVariantOptionsChanged : hasChanges };
    this.updateStickyBarVisibility();
  }

  // This function is used to detect changes in variant objects within the variants list.
  // It compares the new and previous variants list and checks for changes in properties like sku, price, barcode, newArchived, and available.
  // If changes are detected, it updates the changesDetection object and triggers the sticky bar visibility update.
  detectChangesInVariantList (event) {
    const newVariantsList = event.detail.variantsList;
    const previousVariantsList = this.previousVariantsList;

    const { hasChanges } = this.compareVariantLists(previousVariantsList, newVariantsList);
    this.changesDetection = { ...this.changesDetection , isVariantsListChanged : hasChanges };
    this.updateStickyBarVisibility();
  }

  checkHandle (event) {
    this.changesDetection = {...this.changesDetection,checkHandleChanges:event.target.checked}
    this.updateStickyBarVisibility();
  }

  undoHandlerButton () {
    this.addHiddenClass();
  }

  addHiddenClass( ) {
    $('.sticky-bar').addClass('hidden');
  }

  removeHiddenClass () {
    $('.sticky-bar').removeClass('hidden');
  }

  updateStickyBarVisibility() {
    if (this.isLoading) return;

    // Extracting the properties from the changesDetection object
    const {
      fieldChanges,
      descriptionChanges,
      publishChanges,
      isVariantOptionsChanged,
      isVariantsListChanged,
      checkHandleChanges,
    } = this.changesDetection;

    const changes = [
      fieldChanges,
      descriptionChanges,
      publishChanges,
      isVariantOptionsChanged,
      isVariantsListChanged,
      checkHandleChanges
    ];

    // Checking if any of the properties have changes
    // If any property is true, then hasChanges will be true
    const hasChanges = changes.some(change => change);

    if (hasChanges) {
      this.removeHiddenClass();
      this.addEvent('beforeunload', this.handleBeforeUnload);
      this.handleGlobalBeforeCache();
    } else {
      this.removeEvent("turbo:before-render", this.handleBeforeCache);
      this.removeEvent("beforeunload", this.handleBeforeUnload);
      this.removeEvent("popstate", this.handleBeforeCache);
      this.addHiddenClass();
    }
  }


  // Function to add an event listener to the window object
  addEvent(eventName, eventHandler) {
    window.addEventListener(eventName, eventHandler);
  }

  // Function to remove an event listener from the window object
  removeEvent(eventName, eventHandler) {
    window.removeEventListener(eventName, eventHandler);
  }

  // Function to handle global cache before it's loaded
  handleGlobalBeforeCache () {
    window.addEventListener('popstate', this.handleBeforeCache)
  }

  // Function to handle the event before the page unloads
  handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  // Function to handle the event before the cache is loaded
  handleBeforeCache = (event) => {
    if (!($('.sticky-bar').hasClass('hidden'))) {
      event.preventDefault();
      window.history.forward();
      event.returnValue = '';
      $('.back-model').removeClass('hidden');
    }
  };

  // Function to disconnect all event listeners from the window object
  disconnect() {
    this.removeEvent("turbo:before-render", this.handleBeforeCache);
    this.removeEvent("beforeunload", this.handleBeforeUnload);
    this.removeEvent("popstate", this.handleBeforeCache);
    this.removeEvent("select2:changed", this.initPreviousVariantOptionsList.bind(this));
    this.removeEvent("detectVariantOptionChanges", this.detectVariantOptionChanges.bind(this));
    this.removeEvent("detectChangesInVariantList", this.detectChangesInVariantList.bind(this));
  }

  // Function to open the back model if the sticky bar is not hidden
  openBackModel(event) {
    if(!($('.sticky-bar').hasClass('hidden'))) {
      event.preventDefault()
      $('.back-model').removeClass('hidden');
    }
  }

  // Function to close the back model
  closeBackModel() {
    $('.back-model').addClass('hidden');
  }


  /**
   * Compares two option lists and returns the changes between them.
   * @param {Array} prevList - The previous list of options.
   * @param {Array} newList - The new list of options.
   * @param {Number} newSectionsCount - The count of new sections.
   * @param {Number} oldSectionsCount - The count of old sections.
   * @returns {Object} An object containing the changes and a boolean indicating if there are changes.
   */
  compareOptionLists(prevList, newList, newSectionsCount, oldSectionsCount) {
    if (!Array.isArray(prevList) && !Array.isArray(newList)) {
      return {
        changes: {
          added: [],
          removed: [],
          modified: []
        },
        hasChanges: false
      }
    }

    const changes = {
        added: [],
        removed: [],
        modified: [],
        newOptionsList: newList,
        oldOptionsList: prevList,
    };

    const prevMap = new Map(prevList.map(option => [option.sectionId, option]));
    const newMap = new Map(newList.map(option => [option.sectionId, option]));
    // Check for added or modified sections
    newMap.forEach((newOption, sectionId) => {
        const prevOption = prevMap.get(sectionId);
        if (!prevOption) {
            changes.added.push(newOption);
        } else {
            const { optionKey: prevOptionKey, optionValues: prevOptionValues, order: prevOrder } = prevOption;
            const { optionKey: newOptionKey, optionValues: newOptionValues, order: newOrder } = newOption;

            const isOptionKeyChanged = prevOptionKey !== newOptionKey;
            const isOptionValuesChanged = !this.arrayEquals(prevOptionValues, newOptionValues);
            const isOrderChanged = Number(prevOrder) !== Number(newOrder);

            if (isOptionKeyChanged || isOptionValuesChanged || isOrderChanged) {
                changes.modified.push({
                    sectionId,
                    changes: {
                        prevOptionKey,
                        newOptionKey,
                        prevOptionValues,
                        newOptionValues,
                        prevOrder,
                        newOrder
                    }
                });
            }
        }
    });

    // Check for removed sections
    prevMap.forEach((prevOption, sectionId) => {
        if (!newMap.has(sectionId)) {
            changes.removed.push(prevOption);
        }
    });
    const hasChanges = [changes.added.length, changes.removed.length, changes.modified.length].some(length => length > 0) ||
                       newSectionsCount !== oldSectionsCount ||
                       changes.prevOrder !== changes.newOrder;
    this.changesDetection.variantOptionsChanges = {
      added: changes.added,
      removed: changes.removed,
      modified: changes.modified,
      newOptionsList: newList,
      previousOptionsList: prevList,
    }

    return {changes, hasChanges};
  }

  /**
   * Checks if two arrays are equal.
   * @param {Array} arr1 - The first array.
   * @param {Array} arr2 - The second array.
   * @returns {Boolean} True if the arrays are equal, false otherwise.
   */
  arrayEquals(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  }

  /**
   * Reverts the changes made to the options list.
   * @param {Array} newOptionsList - The new list of options.
   * @param {Object} changes - The changes made to the options list.
   * @returns {Array} The reverted list of options.
   */
  revertVariantOptionsChanges (newOptionsList, changes) {
    let revertedList = [...newOptionsList];

    changes.added.forEach(addedSection => {
        revertedList = revertedList.filter(section => section.sectionId !== addedSection.sectionId);
    });

    changes.removed.forEach(removedSection => {
        revertedList.push(removedSection);
    });

    changes.modified.forEach(modifiedSection => {
        const index = revertedList.findIndex(section => section.sectionId === modifiedSection.sectionId);
        if (index !== -1) {
            revertedList[index].optionKey = modifiedSection.changes.prevOptionKey;
            revertedList[index].optionValues = modifiedSection.changes.prevOptionValues;
        }
    });
    this.variantOptionsChanges = { ...this.variantOptionsChanges, newOptionsList: revertedList}

    return revertedList;
  }

  /**
   * Compares two variant lists and returns the changes between them.
   * @param {Array} prevList - The previous list of variants.
   * @param {Array} newList - The new list of variants.
   * @returns {Object} An object containing the changes and a boolean indicating if there are changes.
   */
  compareVariantLists (prevList, newList) {
    if (!Array.isArray(prevList) || !Array.isArray(newList)) {
        return {
            changes: {
                added: [],
                removed: [],
                modified: []
            },
            hasChanges: false
        };
    }

    const changes = {
        added: [],
        removed: [],
        modified: [],
    };

    const prevMap = new Map(prevList.map(variant => [variant.id, variant]));
    const newMap = new Map(newList.map(variant => [variant.id, variant]));

    newMap.forEach((newVariant, id) => {
        const prevVariant = prevMap.get(id);
        if (!prevVariant) {
            changes.added.push(newVariant);
        } else {
            const modifiedProps = this.compareVariants(prevVariant, newVariant);
            if (Object.keys(modifiedProps).length > 0) {
                changes.modified.push({ id, changes: modifiedProps });
            }
        }
    });

    prevMap.forEach((prevVariant, id) => {
        if (!newMap.has(id)) {
            changes.removed.push(prevVariant);
        }
    });

    const hasChanges = [changes.added.length, changes.removed.length, changes.modified.length].some(length => length > 0)

    return { changes, hasChanges };
  }

  /**
   * Capitalizes the first letter of a string.
   * @param {string} string - The string to capitalize.
   * @returns {string} The string with the first letter capitalized.
   */
  capitalizeFirstLetter (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * Compares two variants and returns the properties that have changed.
   * @param {Object} prevVariant - The previous variant.
   * @param {Object} newVariant - The new variant.
   * @returns {Object} An object containing the properties that have changed.
   */
  compareVariants (prevVariant, newVariant) {
    const modifiedProps = {};
    const propertiesToCheck = ['price', 'available', 'sku', 'barcode', 'archived', 'newArchived'];

    propertiesToCheck.forEach(prop => {
      let prevVariantProp = prevVariant[prop];
      let newVariantProp = newVariant[prop];

      if (prop === 'price') {
        prevVariantProp = parseFloat(prevVariantProp).toFixed(1);
        newVariantProp = newVariantProp ? parseFloat(newVariantProp).toFixed(1) : '0.0';
      } else if (prop === 'available') {
        prevVariantProp = Number(prevVariantProp);
        newVariantProp = Number(newVariantProp);
      }

      if (prevVariantProp !== newVariantProp) {
        modifiedProps[`prev${this.capitalizeFirstLetter(prop)}`] = prevVariantProp;
        modifiedProps[`new${this.capitalizeFirstLetter(prop)}`] = newVariantProp;
      }
    });

    return modifiedProps;
  }

  /**
   * Discards all changes made in the description, fields, variant options and variants list.
   * Resets all change detection flags and updates the visibility of the sticky bar.
   */
  discardChanges() {
    // Reset editor changes
    if (this.changesDetection.descriptionChanges) {
      const { contentDocument: iframeDocument } = document.querySelector('iframe');
      const element = iframeDocument.querySelector('[data-id="product_description1"]');
      element.innerHTML = this.descriptionContent;
    }

    // Reset field changes
    if (this.changesDetection.fieldChanges) {
      this.detectFieldsTargets.forEach((field, index) => {
        field.value = this.previousFieldValues[index];
      });
    }

    // Reset variant changes
    if (this.changesDetection.isVariantOptionsChanged || this.changesDetection.isVariantsListChanged) {
      this.revertVariantOptionsChanges(this.changesDetection.variantOptionsChanges.newOptionsList, this.changesDetection.variantOptionsChanges);

      const event = new CustomEvent("revertVariantsChanges");
      const variantListControllerElement = document.querySelector('[data-controller="variant-list-section"]');

      // Remove all the options sections before dispatching the event
      Array.from(document.querySelectorAll(".variant-item-section")).forEach(section => section.remove());

      // Dispatch event
      variantListControllerElement.dispatchEvent(event);
    }

    // Reset all change detection flags
    Object.keys(this.changesDetection).forEach(key => {
      if (typeof this.changesDetection[key] === 'boolean') {
        this.changesDetection[key] = false;
      }
    });

    // Update the visibility of the sticky bar
    this.updateStickyBarVisibility();
  }
}
