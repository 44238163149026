import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tableBodyReplace"];

  connect() {}

  sendRequest({ params }) {
    fetch(params.url, {
      method: "get",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    }).then((response) => response.json());
  }
}
