import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tableBodyReplace", "searchField"];

  connect() {}

  sendRequest({ params }) {
    fetch(params.url, {
      method: "get",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    }).then((response) => response.json());
  }
  searchFilters(event) {
    let request_url =
      this.searchFieldTarget.dataset.url +
      "?search_by=" +
      (event.target.value.toLowerCase() || "") +
      "&index=" +
      (this.searchFieldTarget.dataset.cardIndex || "") +
      "&turbo_frame=" +
      (this.searchFieldTarget.dataset.turboFrame || "");
    fetch(request_url, {
      method: "get",
    })
      .then((response) => response.text())
      .then((htmlResponse) => {
        // conversion from String to HTML
        var parser = new DOMParser();
        var doc = parser.parseFromString(htmlResponse, "text/html");
        var result = doc.querySelector(".body-to-be-replaced");

        if (result) {
          this.tableBodyReplaceTarget.replaceWith(result);
        } else {
          result = "<tr>Sorry, no items found!</tr>";
          this.tableBodyReplaceTarget.innerHTML = result;
        }
      });
  }
  applyFilters({ params }) {
    let request_url =
      params.url +
      "?filters=" +
      (params.filters || "") +
      "&sort_by=" +
      (params.sortBy || "");
    fetch(request_url, {
      method: "get",
    })
      .then((response) => response.text())
      .then((htmlResponse) => {
        // conversion from String to HTML
        var parser = new DOMParser();
        var doc = parser.parseFromString(htmlResponse, "text/html");
        var result = doc.querySelector(".body-to-be-replaced");

        if (result) {
          this.tableBodyReplaceTarget.replaceWith(result);
        } else {
          result = "<tr>Sorry, no items found!</tr>";
          this.tableBodyReplaceTarget.innerHTML = result;
        }
      });
  }
}
