import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ['draggable', 'draggableItem'];
  connect(){
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    })
  }

  end(event){
    event.preventDefault();
    let updatePosition = (id, position) => {
      fetch(`/menu/${this.draggableTarget.id}/update_menu`, {
        method: 'PUT',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          menu: {
            menu_items_attributes: {
              id: id,
              position: position
            }
          }
        })
      })
    }
    this.draggableItemTargets.map(function(t, i) {
      updatePosition(t.id, i+1)
      return{
        id: t.id, position: i+1
      }
    })
  }

}