import { Dropdown } from "tailwindcss-stimulus-components";

export default class FilterDropdownController extends Dropdown {
  static targets = ['menu', 'button'];

  connect() {
    super.connect();
  }

  toggle(event) {
    super.toggle();
  }

  hide(event) {
    super.hide();
  }
}
