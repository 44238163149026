$(document).ready(function () {
  $(".accordian-tab").find(".accordian-body").slideUp();

  $(".accordion-tab-btn").click(function () {
    const parent = $(this).closest(".accordian-tab");
    const hasClassActive = parent.hasClass("active-accordian");
    const accordianParent = parent.closest(".accordian");
    const accordianBody = parent.find(".accordian-body");

    accordianParent.find(".accordian-tab").removeClass("active-accordian");
    accordianParent.find(".accordian-body").slideUp();

    if (!hasClassActive) {
      parent.addClass("active-accordian");
      accordianBody.slideDown();
    }
  });
});
