import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [ 'cardElement', 'cardErrors', 'form',
                       'paymentButton', 'buySubscriptionButton', 'subscriptionName',
                       'selectedCardLayout', 'paymentTypeField', 'subscriptionModal',
                       'cardTypeField', 'paymentTypeField', 'lastDigitField']

    connect() {
      this.stripe = Stripe(this.paymentButtonTarget.dataset.stripeKey);
      var elements = this.stripe.elements();
      var style = {
        base: {
          color: '#000',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      };

      this.card = elements.create('card', {
        style: style
      });
      this.card.mount(this.cardElementTarget);

      // Handle real-time validation errors from the card Element.
      let controller = this;
      this.card.addEventListener('change', function (event) {
        var displayError = controller.cardErrorsTarget;
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });
    }

    submitCard() {
      let form = this;
      let element = this.paymentButtonTarget;
      this.stripe.createToken(this.card).then(function (result) {
        if (result.error) {
          // Inform the user if there was an error.
          var errorElement = form.cardErrorsTarget;
          errorElement.textContent = result.error.message;
        } else {
          // Send the token to your server.
          let request_url = element.dataset.url + "?token_id=" + result.token.id || "";
          form.assignTokenToCustomer(request_url);
        }
      });
    }

    buySubscription() {
      let element = this.buySubscriptionButtonTarget;
      if (element.dataset.cardId) {
        let request_url = element.dataset.url + "&payment_method=" + element.dataset.cardId;
        this.callSubscriptionApi(request_url);
      } else {
        alert('please set default payment method');
      }
    }

    callSubscriptionApi(request_url) {
      fetch(request_url, {
        method: "get",
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status == 'ok') {
            this.subscriptionNameTarget.textContent = response.product.name;
            this.subscriptionModalTarget.click();
          }
        });
    }

    assignTokenToCustomer(request_url) {
      fetch(request_url, {
        method: "get",
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status == 'ok') {
            location.href = response.redirect_url
          } else {
            this.cardErrorsTarget.textContent = response.message;
            location.href = response.redirect_url
          }
        });
    }
}
