import { Controller } from "@hotwired/stimulus";
import jquery from "jquery";

export default class extends Controller {
  static targets = ['collectionConditionsView',
                    'browseButton',
                    'collectionProductsStatus',
                    'newCollectionProducts',
                    'newCollectionConditions', 'sortSelect', 'action',
                    'collectionProductLoading', 'collectionProductsTable', 'collectionConditionsPanel',
                    'collectionReadPanel'];

  connect() {
    const action = this.element.dataset.resourceEditViewValue
    if (action == 'new') {
      this.newHandleStatusChange();
      this.applyStylesToPanel(this.collectionConditionsPanelTarget, 3);
    } else if (action == 'edit') {
      this.editHandleStatusChange();
    }
    this.applyStylesToPanel(this.collectionReadPanelTarget, 2);
  }

  collectionStatusListener(event) {
    const action = this.element.dataset.resourceEditViewValue
    if (action == 'new') {
      this.onNewHandleBrowseButton(event.target.value)
    } else if (action == 'edit') {
      this.collectionProductsStatusTarget.dataset.collectionProductsStatusColumn = event.target.value
      this.onEditHandleBrowseButton(event.target.value)
    }
  }

  newHandleStatusChange() {
    const status = this.newCollectionProductsTarget.dataset.collectionProductsStatusColumn;
    this.onNewHandleBrowseButton(status)
  }

  editHandleStatusChange() {
    const status = this.collectionProductsStatusTarget.dataset.collectionProductsStatusColumn;
    this.onEditHandleBrowseButton(status)
  }

  onNewHandleBrowseButton(status) {
    if (status == 'automatic') {
      this.newCollectionProductsTarget.classList.add('hidden')
      this.newCollectionConditionsTarget.classList.remove('hidden')
    } else {
      this.newCollectionProductsTarget.classList.remove('hidden')
      this.newCollectionConditionsTarget.classList.add('hidden')
    }
  }

  onEditHandleBrowseButton(status) {
    if (status == 'automatic') {
      this.collectionConditionsViewTarget.classList.remove('hidden')
      this.browseButtonTarget.classList.add('hidden')
      this.sortSelectTarget.classList.add('mr-2.5')
      this.actionTargets.forEach(target => target.classList.add('hidden'));
    } else {
      this.collectionConditionsViewTarget.classList.add('hidden')
      this.browseButtonTarget.classList.remove('hidden')
      this.sortSelectTarget.classList.remove('mr-2.5')
      this.actionTargets.forEach(target => target.classList.remove('hidden'));
    }
  }

  sortBy(event) {
    const sort = event.target.value
    const collectionId = this.element.dataset.recordId

    fetch(`/admin/sort_by`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.getCsrfToken()
      },
      body: JSON.stringify({ collection: { sort_by: sort, collection_id: collectionId } })
    })
    .then(response => response.ok ? response.json() : Promise.reject(response))

    .then((data) => {
      if (data.message) {
        this.collectionProductLoadingTarget.classList.remove('hidden')
        this.collectionProductsTableTarget.classList.add('hidden')

        $('#collection_products_frame').load(window.location.href + ' #collection_products_frame > *')

        setTimeout(() => {
          this.hideActionColumn()
        }, 1000)
      }
    })
    .catch((error) => {})
  }

  getCsrfToken() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }

  hideActionColumn() {
    const status = this.collectionProductsStatusTarget.dataset.collectionProductsStatusColumn
    this.collectionProductLoadingTarget.classList.add('hidden')
    this.collectionProductsTableTarget.classList.remove('hidden')

    if (status == 'automatic') {
      this.actionTargets.forEach(target => target.classList.add('hidden'))
    }
  }

  applyStylesToPanel(target, panelIndex) {
    const panel = target.closest(`[data-panel-index="${panelIndex}"]`);
    if (panel) {
      panel.style.background = 'transparent';
      panel.style.padding = '0px';
    }
  }
}
