import { Controller } from "@hotwired/stimulus";
import { red } from "@material-ui/core/colors";
import { i18n } from "../components/locales_handler"

export default class extends Controller {
  static targets = ['deleteModal'];

  connect() {}

  productDeleteModal(event) {
    event.preventDefault();
    const deleteModal = this.deleteModalTarget
    const productId = event.currentTarget.getAttribute("data-resource-id")

    if (event.currentTarget.classList.contains('resource-index-delete')) {
      event.currentTarget.style.setProperty('background', 'transparent', 'important');
      }

    if (deleteModal) {
      deleteModal.classList.remove('hidden');
      deleteModal.dataset.productId = productId;
    }
  }

  deleteProduct(event) {
    const deleteButton = event.target.closest('#delete-product-modal')
    const productId = deleteButton.getAttribute('data-product-id')

    fetch(`/admin/resources/products/${productId}`, {
      method: 'DELETE',
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(i18n.t('product.failed_to_delete_product'));
      }
    })
    .then((data) => {
      if (data.message) {
        window.location.href = '/admin/resources/products';
      } else {
        throw new Error(i18n.t('product.message_not_found_in_response'));
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  closeModal(event) {
    event.preventDefault
    if (this.deleteModalTarget) {
      this.deleteModalTarget.classList.add('hidden');
    }
  }
}
