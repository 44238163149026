const WOW = require("wowjs");

window.wow = new WOW.WOW({
  boxClass: "wow",
  animateClass: "animate__animated",
});

$(document).ready(function () {
  window.wow.init();
});
