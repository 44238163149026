import jquery from "jquery";
import Selectize from 'selectize';

window.jQuery = jquery;
window.$ = jquery;
import Select2 from "select2"
Select2();

$(".add-stock-btn").on("click", function (event) {
  event.preventDefault();
  $(".inventory-section").append(
    $(".sample-inventory-item .inventory-item").html()
  );
});

function initializeSelectize() {
  var optionClasses = {};

  $('#multiple-collection option').each(function() {
    var option = $(this);
    optionClasses[option.attr('value')] = option.attr('class') || '';
  });

  $('#multiple-collection').selectize({
    plugins: ['remove_button'],
    valueField: 'value',
    labelField: 'text',
    searchField: ['text'],
    render: {
      option: function(item, escape) {
        var classAttr = optionClasses[item.value] || '';
        return '<div class="option ' + escape(classAttr) + '" data-selectable data-value="' + escape(item.value) + '">' + escape(item.text) + '</div>';
      },
      item: function(item, escape) {
        var classAttr = optionClasses[item.value] || '';
        return '<div class="item ' + escape(classAttr) + '" data-value="' + escape(item.value) + '">' + escape(item.text) + '</div>';
      }
    },
    onInitialize: function() {
      var self = this;
      self.refreshItems();
    }
  });
}

$(document).on('turbo:load', function() {
  initializeSelectize();
});


Selectize.define('stop_backspace_delete', function(options) {
  var self = this;

  this.deleteSelection = (function() {
      var original = self.deleteSelection;
      return function(e) {
          if (!self.settings.plugins.includes('stop_backspace_delete')) {
              return original.apply(this, arguments);
          }
          var keyCode = e && e.keyCode;
          if (keyCode && keyCode === 8) {
              return;
          }
          return original.apply(this, arguments);
      };
  })();
});
