import { Controller } from "@hotwired/stimulus";
import jquery from "jquery";
import OrderItemsController from "./order_items_controller";
import { i18n } from "../components/locales_handler"

export default class extends Controller {
  static targets = ['errorTitle', 'createProductModal', 'titleTextField', 'newProductButton',
                    'altTextField', 'lgInputFileMultiple', 'checkboxToggle', 'commonInputFile',
                    'commonInputFileWithPreview', 'customInputFileWithPreview', 'toggleContainerCheckBoxes',
                    'imagePreview', 'collectionImagePreview', 'collecttionImageMessage'];

  connect() {
    this.initializeAllComponents();
  }

  initializeAllComponents() {
    this.customizeViewButtons();
    this.initializeToggleButtons();
    this.initializeCommonInputFilesWithPreview();
    this.initializeCustomInputFileWithPreview();
    this.initializeCommonInputFilesWithoutPreview();
    this.initializeLgInputFileMultiple();
    this.initializeToggleCheckboxes();
  }

  initializeOnNestedFields() {
    setTimeout(() => {
      this.initializeAllComponents();
    }, 20);
  }

  initializeToggleButtons() {
    jquery(this.checkboxToggleTargets).each(function (index, input) {
      if (jquery(input).parent().hasClass('custom-checkbox')) return;

      jquery(input).closest('.field-wrapper').addClass('custom-input-checkbox-wrapper');
      jquery(input).parent().addClass('custom-checkbox');
      jquery(input).parent().append('<label class="custom-checkbox-label"></label>')
    });
  }

  initializeCommonInputFilesWithPreview() {
    this.initializeCommonInputFiles(this.commonInputFileWithPreviewTargets, true);
  }

  initializeCommonInputFilesWithoutPreview() {
    this.initializeCommonInputFiles(this.commonInputFileTargets, false);
  }

  initializeCommonInputFiles(inputs, with_preview = false) {
    jquery(inputs).each(function (index, i) {
      const input = jquery(i);
      const superParent = input.closest('.custom-input-file-wrapper');

      if (superParent.length > 0) return;

      const parent = input.parent();

      if (parent.hasClass('common-input-file')) return;

      parent.addClass('common-input-file');

      parent.append('<label class="cif-label"><span class="cif-btn">Choose File</span><span class="cif-label-val">No file chosen</span></label>');

      const previewSvg = `<svg width="67" height="44" viewBox="0 0 67 44" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="67" height="44" fill="#F1F1F1"/><path fill-rule="evenodd" clip-rule="evenodd" d="M46 10.0714H21.1429C20.5935 10.0714 20.0666 10.2897 19.6781 10.6781C19.2897 11.0666 19.0714 11.5935 19.0714 12.1429V30.7857L24.5524 25.9096C24.7217 25.7409 24.9442 25.6359 25.182 25.6125C25.4199 25.5891 25.6585 25.6487 25.8574 25.7811L31.3674 29.4538L39.0524 21.7688C39.206 21.615 39.4041 21.5135 39.6186 21.4785C39.8331 21.4436 40.0532 21.477 40.2476 21.5741L48.0714 25.6071V12.1429C48.0714 11.5935 47.8532 11.0666 47.4647 10.6781C47.0763 10.2897 46.5494 10.0714 46 10.0714V10.0714ZM21.1429 8C20.0441 8 18.9904 8.43648 18.2134 9.21341C17.4365 9.99035 17 11.0441 17 12.1429V32.8571C17 33.9559 17.4365 35.0096 18.2134 35.7866C18.9904 36.5635 20.0441 37 21.1429 37H46C46.544 37 47.0828 36.8928 47.5854 36.6846C48.088 36.4764 48.5447 36.1713 48.9294 35.7866C49.3141 35.4019 49.6193 34.9452 49.8275 34.4425C50.0357 33.9399 50.1429 33.4012 50.1429 32.8571V12.1429C50.1429 11.0441 49.7064 9.99035 48.9294 9.21341C48.1525 8.43648 47.0988 8 46 8H21.1429V8ZM29.4286 17.3214C29.4286 17.7295 29.3482 18.1335 29.1921 18.5105C29.0359 18.8875 28.807 19.23 28.5185 19.5185C28.23 19.807 27.8875 20.0359 27.5105 20.1921C27.1335 20.3482 26.7295 20.4286 26.3214 20.4286C25.9134 20.4286 25.5094 20.3482 25.1324 20.1921C24.7554 20.0359 24.4129 19.807 24.1243 19.5185C23.8358 19.23 23.607 18.8875 23.4508 18.5105C23.2947 18.1335 23.2143 17.7295 23.2143 17.3214C23.2143 16.4974 23.5416 15.707 24.1243 15.1243C24.707 14.5416 25.4974 14.2143 26.3214 14.2143C27.1455 14.2143 27.9358 14.5416 28.5185 15.1243C29.1012 15.707 29.4286 16.4974 29.4286 17.3214V17.3214Z" fill="black" fill-opacity="0.13"/></svg>`;

      if (with_preview) parent.find('.cif-label').prepend(`<span class="cif-img-preview">${previewSvg}</span>`);

      const cifLabelVal = parent.find('.cif-label-val');

      input.change(function (e) {
        if (e.target.files) {
          const filesArray = Array.from(e.target.files);

          if (filesArray.length > 0) {
            const filenames = filesArray.map(function (file) {
              return file.name;
            }).join(', ');

            cifLabelVal.html(filenames);
            parent.find('.cif-img-preview').html(`<img src="${URL.createObjectURL(e.target.files[0])}">`);

            return;
          }
        }

        cifLabelVal.html('No file chosen');
        parent.find('.cif-img-preview').html(previewSvg);
      });
    });
  }

  initializeCustomInputFileWithPreview() {
    this.initializeCustomInputFiles(this.customInputFileWithPreviewTargets, true);
  }

  initializeCustomInputFiles(inputs, with_preview = false) {
    jquery(inputs).each(function (index, i) {
      const input = jquery(i);
      const superParent = input.closest('.custom-input-file-wrapper');

      if (superParent.length > 0) return;

      const parent = input.parent();

      if (parent.hasClass('common-input-file')) return;

      parent.addClass('common-input-file');

      const previewSvg = `<svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>`;

      if (with_preview) parent.find('.cif-label').prepend(`<span class="cif-img-preview flex justify-center">${previewSvg}</span>`);

      const cifLabelVal = parent.find('.cif-label-val');

      input.change(function (e) {
        if (e.target.files) {
          const filesArray = Array.from(e.target.files);

          if (filesArray.length > 0) {
            const filenames = filesArray.map(function (file) {
              return file.name;
            }).join(', ');

            cifLabelVal.html(filenames);
            parent.find('.cif-img-preview').html(`<img src="${URL.createObjectURL(e.target.files[0])}" class='w-32'>`);

            return;
          }
        }

        cifLabelVal.html('No file chosen');
        parent.find('.cif-img-preview').html(previewSvg);
      });
    });
  }

  initializeLgInputFileMultiple() {
    jquery(this.lgInputFileMultipleTargets).each(function (index, input) {
      const parent = jquery(input).parent();
      const labelSection = $(input).closest('.divide-y').find('.label-section').first();

      if (parent.hasClass('custom-input-file')) return;

      parent.closest('.field-wrapper').addClass('custom-input-file-wrapper');
      parent.addClass('custom-input-file');

      parent.append(`<div class="cif-placeholder" data-action="click->avo-custom-inputs#openFileBrowser"><div class="cif-placeh-btn text-xs md:text-base">Drag and drop files here</div><div class="selected-files m-0"></div></div></div>`)

      const selectedFiles = parent.find('.selected-files');
      const cifPlaceholder = parent.find('.cif-placeh-btn');

      $(".custom-input-file").detach().appendTo(labelSection);
      $(labelSection).addClass('attachment-section')

      jquery(input).change(function (e) {
        if (e.target.files) {
          const filesArray = Array.from(e.target.files);

          if (filesArray.length > 0) {
            selectedFiles.html(filesArray.map(function (file) {
              return `<span>${file.name}</span>`;
            }));
            cifPlaceholder.html(i18n.t('avo.product.change_files'));

            return;
          }
        }

        selectedFiles.html('');
        cifPlaceholder.html(i18n.t('avo.product.drag_and_drop'));
      });
    })
  }

  initializeToggleCheckboxes() {
    jquery(this.toggleContainerCheckBoxesTargets).each((index, input) => {
      this.toggleContainer({ target: input });
    })
  }

  toggleContainer(e) {
    const isChecked = e.target.checked;
    const input = jquery(e.target);
    const container = jquery(`[data-field-id=${input.attr('data-toggle-id')}]`);

    if (container.length) {
      if (isChecked) container.slideDown()
      else container.slideUp();
    }
  }

  toggleShippingAddress(event) {
    const isChecked = event.target.checked;
    this.changeBillingAddress(isChecked)
  }

  billingAddressValueChange() {
    const isChecked = this.checkboxToggleTarget.checked
    if (!isChecked) return
    this.changeBillingAddress(isChecked)
  }

  changeBillingAddress(isChecked) {
    const billingAddresses = $('.send-billing-address')
    $(billingAddresses).each(function () {
      const selector = $(`input.take-billing-address[data-address-id=${$(this).data("address-id")}]`)

      if ($(this).val() && isChecked) {
        $(selector).val($(this).val().toString())
      }
      else $(selector).val('')
    })
  }

  displayPreview(event) {
    const imagesName = document.querySelector('.selected-files').classList.add('hidden');
    const input = event.target;
    const previewsContainer = document.querySelector('.custom-input-file');
    const labelSection = $('.custom-input-file').closest('.label-section')
    const controller = event.target.dataset.controller
    const acceptedFormats = [ 'image/png', 'image/jpg', 'image/webp', 'image/jpeg' ]
    let updateAttachmentsEndPiont = '';

    if (this.element.dataset.resourceEditViewValue === "new" && input.files && input.files.length > 0) {
      const divElement = document.createElement('div');

      if (previewsContainer.querySelectorAll('.collections-attatchment-wrapper').length < 1) {
        labelSection.next().remove();
        previewsContainer.classList.add('h-full', 'w-full', 'px-0');
        divElement.classList.add('flex', 'gap-2', 'justify-start', 'z-50', 'collections-attatchment-wrapper', 'flex-wrap', 'py-4', 'my-8');
        previewsContainer.appendChild(divElement);
        previewsContainer.querySelector('.cif-placeholder').classList.add('left-[-28%]', 'relative')
        document.querySelector('label[for="collection_attachments"]').classList.add('absolute', 'top-6')
      }

      Array.from(input.files).forEach((file) => {
        if (acceptedFormats.includes(file.type)) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const previewWrapper = document.createElement('div');
            const preview = document.createElement('img');

            preview.src = e.target.result;
            preview.alt = file.name;
            previewWrapper.classList.add('rounded-lg', 'w-40', 'h-40', 'inline-block', 'mx-1', 'my-1');
            preview.classList.add('object-cover', 'h-full', 'w-full', 'rounded-lg');
            previewWrapper.appendChild(preview);
            document.querySelector('.collections-attatchment-wrapper').appendChild(previewWrapper);
          };
          reader.readAsDataURL(file);
        } else {
          this.acceptedFormats(file);
        }
      });
    } else {
      if (input.files.length > 0) {
        labelSection.append(`<p class="text-xs text-primary normal-case loading-bar">Uploading ${input.files.length} ${input.files.length !== 1 ? 'images' : 'image'}
                                <div class="loading-dots ml-1">
                                  <div class="bounce1"></div>
                                  <div class="bounce2"></div>
                                  <div class="bounce3"></div>
                                </div>
                              </p>`);
      }

      const formData = new FormData();
      formData.append('id', this.element.dataset.recordId);

      Array.from(input.files).forEach((file) => {
       if (acceptedFormats.includes(file.type)) {
          formData.append('image[]', file);
       } else {
        this.acceptedFormats(file)
       }
      });

      if (controller === 'avo/products') {
        updateAttachmentsEndPiont = '/admin/update_product_attachments';
      } else if (controller === 'avo/collections') {
        updateAttachmentsEndPiont = '/admin/update_collection_attachments';
      }

      fetch(updateAttachmentsEndPiont, {
        method: "PATCH",
        body: formData,
        headers: {
          'X-CSRF-Token': this.getCsrfToken()
        }
      })
      .then(response => response.json())
      .then(data => {
        let element = document.querySelector(".main-flash-container");
        if (data && data.message) {
          $('#attachments').load(window.location.href + ' #attachments > *');
          $('.attachment-main-area').load(window.location.href + ' .attachment-main-area > *');
          // Handle success message
          let status = true;
          element.innerHTML += this.custom_flash_message().prototype.custom_flash_message(
            data.message.toString(),
            element,
            status
          );
        } else if (data && data.error) {
          // Handle error message
          let status = false;
          element.innerHTML += this.custom_flash_message().prototype.custom_flash_message(
            data.error.toString(),
            element,
            status
          );
        }
      })
      .catch((error) => {
      })
      .finally(() => {
        // Remove the loading bar once the request is completed
        setTimeout(() => {
          $('.loading-bar, .loading-dots').remove();
          $('.cif-placeh-btn').html(i18n.t('avo.product.drag_and_drop'));
        }, 2000);
      });

    }
  }

  customizeViewButtons() {
    $('[data-control="view-type-toggle-grid"]')
      .find('svg').remove().end()
      .text('Thumbnail View');

    $('[data-control="view-type-toggle-list"]')
      .find('svg').remove().end()
      .text('Carousel View');
  }

  getAttachmentImages(event) {
    const id = $(event.currentTarget).closest('.attachment-area').attr("id");
    const altAttribute = $(`#${id}`).find('.alt-text').attr('alt');
    $('#alt-text').val(altAttribute);
    $('.alt-image-modal').removeClass('hidden');
    $('.alt-image-modal').attr('id', id);
  }

  removeAttachment(event) {
    const id = $(event.currentTarget).attr('id');
    const controller = event.target.getAttribute('controller')
    let deleteEndpoint = '';

    if (controller === 'avo/products') {
      deleteEndpoint = `/admin/delete_product_attachment/${id}`;
    } else if (controller === 'avo/collections') {
      deleteEndpoint = `/admin/delete_collection_attachment/${id}`;
    }

    fetch(deleteEndpoint, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': this.getCsrfToken(),
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      let element = document.querySelector(".main-flash-container");
      if (data && data.message) {
        $('#attachments').load(window.location.href + ' #attachments > *');
        $('.attachment-main-area').load(window.location.href + ' .attachment-main-area > *');
        // Handle success message
        let status = true;
        element.innerHTML += this.custom_flash_message().prototype.custom_flash_message(
          data.message.toString(),
          element,
          status
        );
      } else if (data && data.error) {
        // Handle error message
        let status = false;
        element.innerHTML += this.custom_flash_message().prototype.custom_flash_message(
          data.error.toString(),
          element,
          status
        );
      }
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
      // Handle the error
    });
  }

  closeModal() {
    $('.alt-image-modal').addClass('hidden');
  }

  submit(event) {
    const recordId = $('[data-record-id]').data('record-id');
    const altTextValue = this.altTextFieldTarget.value;
    const imageId = $('.alt-image-modal').attr('id');
    const breakImageId = imageId.split('_')[1];
    const controller = event.target.getAttribute('controller')
    let updateAltTextEndPiont = '';

    if (controller === 'avo/products') {
      updateAltTextEndPiont = `/admin/update_product_attachments_alt?record_id=${recordId}&image_id=${breakImageId}&alt_text=${altTextValue}`;
    } else if (controller === 'avo/collections') {
      updateAltTextEndPiont = `/admin/update_collection_attachments_alt?record_id=${recordId}&image_id=${breakImageId}&alt_text=${altTextValue}`;
    }

    fetch(updateAltTextEndPiont, {
      method: "PATCH",
      headers: {
        'X-CSRF-Token': this.getCsrfToken()
      }
    })
    .then((response) => response.json())
    .then((data) => {
      let element = document.querySelector(".main-flash-container");
      element.innerHTML += this.custom_flash_message().prototype.custom_flash_message(
        data.message.toString(),
        element,
        true
      );
      $(`#${imageId}`).load(window.location.href + ` #${imageId} > *`);
      $('.alt-image-modal').addClass('hidden');
    })
    .catch((error) => {
    });
  }

  custom_flash_message() {
    return OrderItemsController;
  }

  createProductModalOpen(event) {
    event.preventDefault()

    this.createProductModalTarget.classList.remove('hidden');
  }

  createProduct() {
    const titleValue = this.titleTextFieldTarget.value;

    if(titleValue != "") {

      const product = {
        title: titleValue,
      };

      fetch('/admin/resources/products', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ product })
      })
      .then(response => {
        window.location.href = response?.url;
        this.createProductModalTarget.classList.add('hidden');
        this.titleTextFieldTarget.value = "";
      })
      .catch(error => {});
    } else {
      if (titleValue === "") {
        this.errorTitleTarget.textContent = i18n.t('enter_title');
      }
    }
  }

  newProductCloseModal() {
    this.createProductModalTarget.classList.add('hidden');
  }

  detectInputFieldValue(event) {
    this.errorTitleTarget.textContent = event.target.value ? '' : i18n.t('enter_title');
  }

  acceptedFormats(file) {
    let status = false;
    let element = document.querySelector(".main-flash-container");

    let data = `<p>${file?.name} ${i18n.t('avo.product.file_could_not_be_added')}</p><small>${i18n.t('avo.product.supported_files_are')}</small>`;
    element.innerHTML += this.custom_flash_message().prototype.custom_flash_message(
      data,
      element,
      status
    );
  }

  openFileBrowser() {
    this.lgInputFileMultipleTarget.style.display = 'none';
    this.lgInputFileMultipleTarget.click()
  }

  getCsrfToken() {
    return document.querySelector("meta[name='csrf-token']").getAttribute("content")
  }
}
