import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "nameField",
    "unique",
    "domainNameField",
    "titleField",
    "submitBtn",
  ];

  connect() {
    this.dashCharRegex = /\-+/g;
    this.specialCharsRegex = /[^\w ]/g;
    this.spaceRegex = /\s+/g;
  }

  storeNameListener(event) {
    let element = event.target;
    let request_url =
      element.dataset.url + "?name=" + (element.value.toLowerCase() || "");
    let dashToSpaceChars = element.value.replace(this.dashCharRegex, " ");
    let removedSpecialChars = dashToSpaceChars.replace(
      this.specialCharsRegex,
      ""
    );
    let domain_name = removedSpecialChars.replace(this.spaceRegex, "-");

    if (this.hasTitleFieldTarget) {
      this.titleFieldTarget.value = element.value;
    }

    if (this.hasDomainNameFieldTarget) {
      this.domainNameFieldTarget.value = domain_name.toLowerCase();
    }

    this.nameUniqueApi(request_url);
  }

  nameUniqueApi(request_url) {
    fetch(request_url, {
      method: "get",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.exists) {
          this.uniqueTarget.classList.remove("hidden");
          this.submitBtnTarget.disabled = true;
          this.submitBtnTarget.style.opacity = 0.5;
        } else {
          this.uniqueTarget.classList.add("hidden");
          this.submitBtnTarget.disabled = false;
          this.submitBtnTarget.style.opacity = 1;
        }
      });
  }
}
