import { Application, defaultSchema } from "@hotwired/stimulus";
import {
  Alert,
  Autosave,
  Dropdown,
  Tabs,
  Popover,
  Toggle,
  Slideover,
} from "tailwindcss-stimulus-components";
import Remote from "stimulus-remote-rails";

const application = Application.start();
const customSchema = {
  ...defaultSchema,
  keyMappings: {
    ...defaultSchema.keyMappings,
    backspace: "Backspace"
  }
}

application.register("remote", Remote);
application.register("alert", Alert);
application.register("autosave", Autosave);
application.register("dropdown", Dropdown);
application.register("tabs", Tabs);
application.register("popover", Popover);
application.register("toggle", Toggle);
application.register("slideover", Slideover);

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;
application.schema = customSchema;

export { application };
