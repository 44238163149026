import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "orderItemCheck",
    "itemPrice",
    "itemNewPrice",
    "variantField",
    "productField",
    "orderItemPrice",
    "quantityField",
    "quantityMessage",
    "maxQuantity",
    "status"
  ];

  connect() {
    if (this.hasVariantFieldTarget) {
      let element = this.variantFieldTarget;
      if (element.options[element.selectedIndex].value) {
        let request_url =
          element.dataset.url +
          "?id=" +
          element.options[element.selectedIndex].value;
        this.fetch_product_info(request_url);
      }
    }
  }

  get_product_from_variant() {
    let element = this.variantFieldTarget;
    if (element.options[element.selectedIndex].value) {
      let request_url =
        element.dataset.url +
        "?id=" +
        element.options[element.selectedIndex].value;

      this.fetch_product_info(request_url);
    } else {
      this.quantityFieldTarget.dataset.maxAvailableQuantity = 0;
      this.maxQuantityTarget.textContent = 0;
      this.quantityMessageTarget.classList.add('hidden');
    }
  }

  fetch_product_info(request_url) {
    fetch(request_url, {
      method: "get",
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status == 200) {
          this.productFieldTarget.value = response.product.id;
          this.quantityFieldTarget.dataset.maxAvailableQuantity = response.max_available_quantity;
          this.quantity_limit_for_variant(response.max_available_quantity, this.quantityFieldTarget.value);

          this.orderItemPriceTargets.map((item) => {
            item.dataset.unitPrice = response.variant.price;
            let amount =
              response.variant.price * this.quantityFieldTarget.value;
            item.textContent = amount;
            item.value = amount;
          });
        }
      });
  }

  calculate_item_price(event) {
    let quantity = event.target.value;
    if (quantity < 0) return event.target.value = 0;

    if (event.target.dataset.maxAvailableQuantity > 0) {
      this.quantity_limit_for_variant(event.target.dataset.maxAvailableQuantity, event.target.value);
    }

    this.orderItemPriceTargets.map((item) => {
      let price = 0
      if (item.dataset.hasOwnProperty('unitPrice')) {
        price = item.dataset.unitPrice;
      }

      item.textContent = price * quantity;
      item.value = price * quantity;
    });
  }

  quantity_limit_for_variant(max_quantity, input_quantity) {
    this.maxQuantityTarget.textContent = max_quantity;

    if (parseInt(input_quantity) <= parseInt(max_quantity)) {
      this.quantityMessageTarget.classList.add('hidden');
    } else {
      this.quantityMessageTarget.classList.remove('hidden');
    }
  }

  select_change(event) {
    if (event.target.value == "Please select") return;
    var request_url = event.params.url + "&variant_id=" + event.target.value;
    fetch(request_url, {
      method: "post",
    })
      .then((res) => res.text())
      .then((response) => {
        response = JSON.parse(response);
        if (response.success == false) {
          let status = false
          let element = document.querySelector(".main-flash-container");
          element.innerHTML += this.custom_flash_message(
            response.message.toString(),
            element,
            status
          );
        } else {
          document.getElementById("order-modify-section").innerHTML =
            response.order_content;
          document.querySelector(".modal-result-section").innerHTML =
            response.order_model_content;
        }
      });
  }

  check_change(event) {
    let hideDeleteButton = false;
    let elementClass = event.params.payload;

    if (event.target.classList.contains("modify-checkbox")) {
      let selectedCheckboxList = document.querySelectorAll(".modify-checkbox");
      this.selectedOrderItems(
        selectedCheckboxList,
        elementClass,
        hideDeleteButton
      );
    } else {
      this.selectedOrderItems(
        this.orderItemCheckTargets,
        elementClass,
        hideDeleteButton
      );
    }
  }

  selectedOrderItems(array, element, hidden) {
    array.forEach(function (elem) {
      if (elem.checked) {
        document.querySelector(element.class).removeAttribute('hidden');
        hidden = true;
      }
    });
    if (hidden) return;
    document.querySelector(element.class).setAttribute('hidden', '');
    return;
  }

  quantity_change(event) {
    if (event.target.value < 0) event.target.value = 0;
    let newValue =
      Number(event.target.value) *
      Number(event.target.getAttribute("data-price"));
    this.itemPriceTarget.innerHTML = "€ " + newValue.toString();
    this.itemNewPriceTarget.value = newValue;
  }

  destroy_order_items(event) {
    let items_ids = [];

    if (event.target.classList.contains("summary-toggle-delete-btn")) {
      this.selected_delete_items(this.orderItemCheckTargets, items_ids);
    } else {
      let selectedCheckboxList = document.querySelectorAll(".modify-checkbox");
      this.selected_delete_items(selectedCheckboxList, items_ids);
    }

    var request_url = event.params.url + "&ids=" + items_ids;
    if (items_ids.length == 0) return;

    fetch(request_url, {
      method: "delete",
    })
      .then((res) => res.text())
      .then((response) => {
        response = JSON.parse(response);
        let status = false
        if (response.success == false) {
          let element = document.querySelector(".main-flash-container");
          element.innerHTML += this.custom_flash_message(
            response.message,
            element,
            status
          );
        } else {
          document.getElementById("order-modify-section").innerHTML =
            response.content;
          document.querySelector(".modal-result-section").innerHTML =
            response.order_model_content;
        }
      });
  }

  selected_delete_items(array, items_ids) {
    array.forEach(function (elem) {
      if (elem.checked) {
        const id = +elem.getAttribute("id");
        items_ids.push(id);
      }
    });
    return;
  }

  modify_order_form_modal({ params }) {
    fetch(params.url)
      .then((res) => res.text())
      .then((response) => {
        this.fetch_order_modal_response(response);
      });
  }

  fetch_order_modal_response(response) {
    response = JSON.parse(response);
    if (response.success == false) {
      let status = false
      let element = document.querySelector(".main-flash-container");
      element.innerHTML += this.custom_flash_message(
        response.message.toString(),
        element,
        status
      );
    } else {
      const fragment = document
        .createRange()
        .createContextualFragment(response.content);
      let selector = document.querySelector(".modal-result-section");
      selector.innerHTML = "";
      selector.appendChild(fragment);
      document.querySelector(".open-modal").click();
      document.querySelector("body").style.top = "0px";
    }
  }

  modify_order_success(event) {
    this.close_order_modal_action(event);
  }

  modify_order_error(event) {
    this.close_order_modal_action(event);
  }

  close_order_modal_action(event) {
    let [data, status, xhr] = event.detail;

    if (status.includes("Unprocessable Entity")) {
      let element = document.querySelector(".main-flash-container");
      element
        .querySelectorAll(".inventory-custom-flash")
        .forEach((el) => el.remove());
      JSON.parse(xhr.response).message.forEach((msg) => {
        let status = false
        element.innerHTML += this.custom_flash_message(msg, element, status);
      });
      return;
    }

    if (JSON.parse(xhr.response).error) return;

    document.getElementById("order-modify-section").innerHTML = JSON.parse(
      xhr.response
    ).content;
    document.querySelector(".product-variant-inventory-model").click();
  }

  custom_flash_message(message, element, status) {
    if (document.querySelector(".toggle-delete-btn"))
      document.querySelector(".toggle-delete-btn").classList.add("opacity-0");
    element
      .querySelectorAll(".inventory-custom-flash")
      .forEach((el) => el.remove());
    setTimeout(
      () =>
        element
          .querySelectorAll(".inventory-custom-flash")
          .forEach((el) => el.remove()),
      5000
    );

    return `<div class='animate-fade mt-2 inventory-custom-flash max-w-sm w-full shadow-lg rounded px-4 py-3 relative ${ status == true ? 'bg-green-400 border-green-700' : 'bg-red-400 border-red-700'} text-white pointer-events-auto top-50'>
                <div class='p-2 custom-flash-item'>
                  <div class="flex items-start">
                    <div class='ml-3 w-0 flex-1 pt-0.5'>
                      <p class='text-sm leading-5 font-medium'>${message}</p>
                    </div>
                    <div class="ml-4 flex-shrink-0 flex">
                      <button data-action="alert#close" class="inline-flex text-white focus:outline-none focus:text-gray-300 transition ease-in-out duration-150">
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
            </div>`;
  }

  updatedStatus () {
    fetch(`/admin/update_order_status?orderId=${this.statusTarget.dataset.orderId}&status=${this.statusTarget.value}`, {
      method: "PATCH",
    }).then((response) => response.json())
  }
}
