import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';
import { i18n } from "../components/locales_handler"


export default class extends Controller {
  static targets = [
    "optionKey", "optionValue", "variantItemSection", "addOption",
    "variantOptionsLayout", "variantsListSection",
    "variantsListBody", "nameOutput", "nestedForm", "remember",
    "options", "toggleable", "filterOptionCheckbox", "selectAllVariants",
    'selectedVariantListItem', 'bulkActionsMenu', 'bulkActionsButton'
  ]

  static deleteAllVariantOption = false;

  connect() {
    this.closeMenuOutsideClick = this.closeMenuOutsideClick.bind(this);
    /**
     * Adds an event listener for the "manageVariantsList" event to this controller.
     * The event will trigger the variantListManagement method, typically called from
     * changeOptionKeyHandler in variant_item_section_controller when the select2 selection changes.
     */
    this.element.addEventListener("manageVariantsList", this.variantListManagement.bind(this));
    this.element.addEventListener('revertVariantsChanges', this.afterConnect.bind(this));
    // add this event in document to access variantListManagement from any other controller
    document.addEventListener("variantListManagement", this.variantListManagement.bind(this));
    this.optionsFilterArray = []
    this.selectedFilterOptions = []; //store the selected filter options array
    this.selectedVariantItems = [];
    this.optionsList = []
    this.optionsListAllSelectors = []
    this.currentOptionListSelector = []
    this.selectedVariantListItemTargets.map(x => x.checked = false)
    this.selectAllVariantsTarget.checked = false

    // this.addBulkActionsScrollListener();
    this.addMoreOptionLinkHandler();
    this.afterConnect();
  }

  afterConnect() {
    const { variantsList, optionsList } = this.getProductVariantsForEditViewAndSetTheVariantList();
    const isOptionSectionOrder = optionsList.every(option => option.hasOwnProperty('order'))

    if (!isOptionSectionOrder) {
      const orderedOptionsList = this.populateOrderInOptionsList(optionsList);
      this.optionsList = orderedOptionsList;
    }

    this.variantsList = variantsList;
    this.optionsList = optionsList;
    // this make sure that variant-item-sections are not collapsed after page reload
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent("toggleVariantSection"));
      document.dispatchEvent(new CustomEvent("initializeSortableLists"))
    }, 120);
    window.dispatchEvent(new CustomEvent("select2:changed",{detail: { variantOptionsList: optionsList, variantOptionSectionsCount: this.variantItemSectionTargets.length, variantsList: variantsList  }}))
  }

  populateOrderInOptionsList(optionsList) {
    optionsList.forEach((option, index) => {
      if (!option.hasOwnProperty('order')) {
        option.order = index + 1;
      }
    });
    return optionsList;
  }

  getProductVariantsForEditViewAndSetTheVariantList() {
    let variantsData = [];
    let optionsList = [];

    if (this.variantsListSectionTarget.dataset.variants !== ' ') {
      try {
        variantsData = JSON.parse(this.variantsListSectionTarget.dataset.variants);
        if (variantsData.length > 0) {
          variantsData = this.mapVariantDataForVariantList(variantsData);
          optionsList = variantsData[0].options ? JSON.parse(variantsData[0].options) : [];
          this.optionsListAllSelectors =  optionsList.map(option => option.optionKey).flat()
          if (optionsList) {
            this.optionsListAllSelectors.forEach((option) =>  {
              this.currentOptionListSelector = option
              this.addNewOptionLayoutForEditView();
            })
          }
        }
      } catch (e) {
        console.error('Error parsing JSON:', e);
      }
    }

    setTimeout(() => {
      this.resetVariantItemSectionId(optionsList)
      this.setVariantItemSectionValues(optionsList)
    }, 100);

    return {
      variantsList: variantsData,
      optionsList: optionsList
    };
  }

  mapVariantDataForVariantList(variantsData) {
    variantsData.forEach(variant => {
      variant.listId = this.sixDigitRandomID();
      // Convert the price to a float and limit it to two decimal places
      variant.price = parseFloat(variant.price).toFixed(1);
      variant.variantCombinations = variant.title.split('/');
      delete variant.title;
      variant.newArchived = variant.archived
      variant.avatar_url = variant.avatar_url
    });

    return variantsData
  }

  // display variantItemSection based on optionsList
  addNewOptionLayoutForEditView() {
    const event = new Event("click");
    this.addOptionTarget.dispatchEvent(event);
    $(`.select-box`).select2('close')
  }

  // Reset variantItemSection data-section-id
  resetVariantItemSectionId(optionsList) {
    const variantItemSections = this.variantItemSectionTargets
    variantItemSections.forEach((variantItemSection, index) => {
      variantItemSection.dataset.id = optionsList[index].sectionId;
      variantItemSection.dataset.sectionId = optionsList[index].sectionId;
      variantItemSection.dataset.order = optionsList[index].order;
    });
  }

  setVariantItemSectionValues(optionsList) {
    const variantOptionsLayout  = document.querySelector('.variant-options-layout')

    optionsList.forEach(data => {
      const  { sectionId, optionValues } = data;
      let variantItemSection = $(variantOptionsLayout).find(`.variant-item-section[data-id="${sectionId}"]`);
      let optionSection = $(variantItemSection).find('.option-section')
      let optionFieldSet = $(variantItemSection).find('.option-fieldset')

      optionValues.forEach(value => {
        const inputElement = $(variantItemSection).find(':input:empty:last');

        if (inputElement.length > 0) {
          // Assuming value is a string, you may need to adapt based on your data
          $(inputElement).val(value);
          $(inputElement).attr('data-value', value);
          $(inputElement).data('sectionId', parseInt(sectionId, 10));
          $(inputElement).attr('data-section-id', parseInt(sectionId, 10));

          // Call the function to add a new empty input field
          this.callCloneEmptyOptionInput(optionSection, optionFieldSet);
        }
      });
    });
    // dispatch this event in variant_item_controller that triggers addSpanElement
    document.dispatchEvent(new CustomEvent("addSpanElementForOption"));

    this.variantListManagement();
  }

  callCloneEmptyOptionInput(optionSection, optionFieldSet) {
    // Get an instance of the VariantItemSectionController
    const variantItemSectionController = this.application.getControllerForElementAndIdentifier(
      this.variantItemSectionTarget,
      'variant-item-section'
    );

      // Check if the controller instance exists
      if (variantItemSectionController) {
        // Call the method on the other controller instance
        variantItemSectionController.cloneEmptyOptionInput(optionSection, optionFieldSet);
        // variantItemSectionController.updateDeleteButtonVisibility()
      } else {
        console.error('Controller instance not found for variant-item-section');
      }
  }

  addBulkActionsScrollListener() {
    function throttle(callback, delay) {
      let isThrottled = false;
      return function () {
        if (!isThrottled) {
          callback.apply(this, arguments);
          isThrottled = true;
          setTimeout(() => {
            isThrottled = false;
          }, delay);
        }
      };
    }
    window.addEventListener("scroll", throttle(this.bulkActionsScrollHandler.bind(this), 1000));
  }

  bulkActionsScrollHandler() {
    const bulkActionsElement = $('.bulk-actions-menu').parent();
    const bulkActionsMenuDropdown = $(bulkActionsElement).find('.bulk-actions-menu-dropdown');
    let classesAdded = false;

    window.addEventListener("scroll", () => {
      const rect = bulkActionsElement[0].getBoundingClientRect();
      const shouldAddClasses = rect.bottom <= 0 || rect.top >= window.innerHeight;

      if (shouldAddClasses !== classesAdded) {
        $(bulkActionsElement).toggleClass('left-[40%] fixed bottom-0 z-[10000]', shouldAddClasses);
        $(bulkActionsMenuDropdown).toggleClass('mt-2 bottom-[75%]', shouldAddClasses);

        classesAdded = shouldAddClasses;
      }
    });
  }

  toggleBulkActions() {
    this.bulkActionsMenuTarget.classList.toggle('hidden');
    if (!this.bulkActionsMenuTarget.classList.contains('hidden')) {
      document.addEventListener('click', this.closeMenuOutsideClick);
    } else {
      document.removeEventListener('click', this.closeMenuOutsideClick);
    }
  }

  closeMenuOutsideClick = (event) => {
    if (!this.element.contains(event.target)) {
      this.bulkActionsMenuTarget.classList.add('hidden');
      document.removeEventListener('click', this.closeMenuOutsideClick);
    }
  }

  //update variants selection and manage selected variants state
  updateVariantSelection(isChecked) {
    this.filterOptionCheckboxTargets.map(filterOptions => filterOptions.querySelector('input[type=checkbox]').checked = isChecked);
    this.selectAllVariantsTarget.checked = isChecked;
    this.selectedVariantListItemTargets.map(x => x.checked = isChecked);
    this.updateSelectedFilters();
  }

  //filter dropdown toggle visibility action
  filterCheckboxToggleHandler(event) {
    const buttonText = event.currentTarget.innerText;
    const isSelectAllVariants = buttonText === 'All';
    const isSelectNone = buttonText === 'None';

    isSelectAllVariants ? this.updateVariantSelection(true) : isSelectNone ? this.updateVariantSelection(false) : null;

    const toggleableDiv = this.findToggleableDiv(event.currentTarget);
    const shouldShowToggleableDiv = event.currentTarget.getAttribute('aria-expanded') === 'false';

    this.hideAllToggleableDivs();
    toggleableDiv.classList.toggle("hidden", !shouldShowToggleableDiv);
    event.currentTarget.setAttribute('aria-expanded', shouldShowToggleableDiv ? 'true' : 'false');
  }

  // Find the toggleable div associated with the clicked element
  findToggleableDiv(element) {
    const liElement = element.closest("li");

    return liElement.querySelector("[data-variant-list-section-target='toggleable']");
  }

  // Hide all toggleable divs on the page
  hideAllToggleableDivs() {
    const allToggleableDivs = this.element.querySelectorAll("[data-variant-list-section-target='toggleable']");
    allToggleableDivs.forEach(div => {
      div.classList.add("hidden");
      // also update the aria-expanded attribute for each button to 'false'
      const button = div.closest("li").querySelector("[data-action='click->variant-list-section#filterCheckboxToggleHandler']");
      button.setAttribute('aria-expanded', 'false');
    });
  }

  // Select All checkbox handler
  handleAllVariantSelection() {
    const isChecked = this.selectAllVariantsTarget.checked;
    this.updateVariantSelection(isChecked)
  }

  findMatchedClasses(variantItemSelectedClasses) {
    // Function to extract classes containing "size," "color," or "material"
    const extractSpecialClasses = (classes) => {
      return Object.values(classes).filter(value => value.includes('size') || value.includes('color') || value.includes('material'));
    };

    // Extract classes containing "size," "color," or "material" for each class list
    const variantSpecialClasses = extractSpecialClasses(variantItemSelectedClasses);

    return {
        matchedVariantSpecialClasses: variantSpecialClasses
    };
  }

  getVariantsWithMatchedClasses(classesArray) {
    const selector = classesArray.map(className => `.${className}`).join(', ');
    return document.querySelectorAll(selector);
  }

  areAllCheckboxesChecked(elements) {
    // Convert NodeList to Array for easier manipulation
    const elementArray = Array.from(elements);

    // Check if all checkboxes inside the elements are checked
    return elementArray.every(element => {
        const checkbox = element.querySelector('input[type="checkbox"]');
        return checkbox ? checkbox.checked : false;
    });
  }

  // Function to get elements with specified classes within the container element
  getElementsWithClasses(container, classesArray) {
    const selector = classesArray.map(className => `.${className}`).join(', ');
    return container.querySelectorAll(selector);
  }

  // Function to check if all checkboxes for specific classes are checked
  areAllVariantsSelected(container, classesToFind) {
    return classesToFind.map(className => {
      const variantsWithMatchedClasses = this.getElementsWithClasses(container, [className]);
      return this.areAllCheckboxesChecked(variantsWithMatchedClasses);
    });
  }

  //manage selected variants and filter list checkbox clicks
  selectedVariantListItemHandler(event) {
    const variantChecked =  event.target.checked;
    this.updateSelectedVariants();
    const variantClasses = event.target.closest('.variant-list').parentElement.classList;
    //get classes starts with size, color, material, style
    const { matchedVariantSpecialClasses } = this.findMatchedClasses(variantClasses);

    const containerElement = document.querySelector('.nested-variant-forms');
    const areAllClassesSelected = this.areAllVariantsSelected(containerElement, matchedVariantSpecialClasses);
    // Use areAllClassesSelected array as needed in your logic
    areAllClassesSelected.forEach((isClassSelected, index) => {
      const className = matchedVariantSpecialClasses[index];
      // Find the checkbox item in filterOptionCheckboxTargets with the matching class
      const matchingCheckbox = Array.from(this.filterOptionCheckboxTargets).find(checkboxTarget => {
          return checkboxTarget.closest('div').classList.contains(className);
      });
      const checkboxInput = matchingCheckbox.querySelector('input[type=checkbox]');
      const isMatchingCheckboxChecked = checkboxInput.checked;

      if (isClassSelected && matchingCheckbox && !isMatchingCheckboxChecked) {
        checkboxInput.checked = true;
      }
      else if (!isClassSelected && isMatchingCheckboxChecked) {
        checkboxInput.checked = false;
      }
      this.updateSelectedVariants();
    });

    //toggle header variant checkbox input on variant items check
    const isAllVariantsSelected = this.selectedVariantListItemTargets.map(x => x.checked).includes(false);
    this.selectAllVariantsTarget.checked = !isAllVariantsSelected;
  }

  variantItemSectionTargetConnected(element) {
    this.addMoreOptionLinkHandler()
  }

  handleOptionInputValue(event) {
    const inputValue = event.target.value
    if (inputValue == '' && !event.target.dataset.value  == '') return
    this.variantListManagement();
    let variantItemSectionId = $(event.target.closest('.variant-item-section')).data('id')
    const variantTitles = document.querySelectorAll('.variant_title');
    variantTitles.forEach((title, index) => {
      if (title.textContent.includes(inputValue)) {
        $(title).closest('.fields').attr('section-id', variantItemSectionId)
      }
    });
  }

  handlePreviousOptionInputValue (event) {
    const inputValue = event.target.value
    const currentVariantItemSectionTarget = event.target.closest('.variant-item-section')
    this.handleDuplicateOptionKey(inputValue, event.target, currentVariantItemSectionTarget)
    this.variantListManagement();
  }

  handleDuplicateOptionKey(inputValue, target, currentVariantItemSectionTarget) {
    // Check for duplicate values excluding the current input
    const isDuplicate = Array.from(currentVariantItemSectionTarget.querySelectorAll(".variant_input"))
    .filter(field => field !== target)  // Exclude the current input
    .some(field => field.value.trim() === inputValue);

    if (isDuplicate) {
      // Slice the input value until it becomes unique
      let slicedValue = inputValue;
      let counter = 1;

      while (Array.from(currentVariantItemSectionTarget.querySelectorAll(".variant_input"))
        .some(field => field.value.trim() === slicedValue)) {
        slicedValue = inputValue.slice(0, -counter.toString().length) + counter;
        counter++;
      }

      // Update the input value and dataset value
      target.value = slicedValue;
      target.dataset.value = slicedValue;
    }
  }

  storeCartesianCombinationsList(variant){
    const getStoreCartesianCombinationList = this.cartesianVariants(variant);
    return getStoreCartesianCombinationList;
  }

  // A main function from where all the logic starts

  variantListManagement() {
    document.dispatchEvent(
      new CustomEvent("option", {
        detail: { optionsList: this.optionsList,variantsList:this.variantsList }
      })
    );

    this.generateOptionsList();

    // this.generateFiltersList() // TODO: generate data for filters
    const cartesianCombinations = this.cartesianVariants(this.optionsList);
    const bestMatchList = this.findBestPerfectMatch(cartesianCombinations, this.variantsList);
    this.variantsList = bestMatchList;

    if (this.variantsList.length > 0) {
      const filter_options_list_html = this.element.querySelector(".filter-options");
      filter_options_list_html.classList.remove("hidden");
      this.variantsListSectionTarget.classList.remove("hidden");
      this.variantsListBodyTarget.textContent = "";

      /* create filter div and populate data
         All/None do not have any optionValues. so, prevent creating filters for it
      */
      if (this.optionsFilterArray.length > 2) {
        filter_options_list_html.innerHTML = "";
        // Check if filters ul element is present
        if (filter_options_list_html) {
          const selectLabel = document.createElement('div');
          selectLabel.textContent = 'Select';
          filter_options_list_html.appendChild(selectLabel);
          // create list item for each option value and append to ul
          this.optionsFilterArray.forEach(filterItem => {
            const listItem = this.buildListItem(filterItem);
            filter_options_list_html.appendChild(listItem);
          });
        }
      }

      // Display existing variants without adding new rows
      this.variantsList.forEach((variant) => {
        const { available, barcode, listId, price, sku, variantCombinations, options, id, archived, newArchived, avatar_url } = variant;

        const variantListItems = [
          { fieldName: 'title', value: variantCombinations.join('/'), listId: listId },
          { fieldName: 'combination', value: variantCombinations.join('/'), listId: listId },
          { fieldName: 'price', value: price, listId: listId },
          { fieldName: 'available', value: available, listId: listId },
          { fieldName: 'sku', value: sku, listId: listId },
          { fieldName: 'barcode', value: barcode, listId: listId },
          { fieldName: 'edit_object', value: listId, listId: listId },
          { fieldName: 'delete_object', value: listId, listId: listId },
          { fieldName: 'options', value: options, listId: listId },
          { fieldName: 'id', value: id, listId: listId },
          { fieldName: 'archived', value: archived, listId: listId },
          { fieldName: 'avatar', value: avatar_url, listId: listId },
        ];

        let element = document.querySelector('.editable-variant-modal');
        if (element) {
          element.classList.add('editable-variant-modal-' + listId);
        }

        // Add existing variants to the DOM without triggering new row addition
        const newRow = this.nestedFormTarget.cloneNode(true);

        if (archived) {
          newRow.style.display = 'none';
          newRow.querySelectorAll('input, select, textarea, .variant-add-image-icon, .variant_title').forEach(input => {
            input.readOnly = true;
            input.style.pointerEvents = 'none';

            if (input.classList.contains('variant_title') || input.classList.contains('variant_combination')) {
              input.disabled = true;
            }
          });
        }

        newRow.classList.remove("hidden");
        newRow.dataset.listId = listId;
        const uniqueId = new Date().valueOf();
        newRow.querySelectorAll('[name^="product[variants_attributes]"]').forEach(field => {
          field.name = field.name.replace(/\[0\]/, `[${uniqueId}]`);
          field.id = field.id.replace(/\[0\]/, `[${uniqueId}]`);
        });
        this.variantsListBodyTarget.appendChild(newRow);

        let nested_forms_list = this.variantsListBodyTarget.querySelectorAll(".nested-form-fields");
        this.assignValuesToVariantListItem(nested_forms_list[nested_forms_list.length - 1], variantListItems);
        this.assignUniqueClassesToVariants(nested_forms_list[nested_forms_list.length - 1], variant, this.optionsFilterArray);

        if (newArchived) {
          this.manageCurrentVariantFieldItem(true, newRow.querySelector('.nested-form-fields'))
        }
      });

    } else {
      const filter_options_list_html = this.element.querySelector(".filter-options");
      this.variantsListSectionTarget.classList.add("hidden");
      this.variantsListBodyTarget.textContent = "";
      filter_options_list_html.classList.add("hidden");
      filter_options_list_html.textContent = "";
    }

    // dispatch detectVariantOptionChanges() binded in detect_product_changes_controller to reflect latest changes in this.optionsLi
    const eventDetail = { variantOptionsList: this.optionsList, variantOptionSectionsCount: this.variantItemSectionTargets.length };
    window.dispatchEvent(new CustomEvent("detectVariantOptionChanges", { detail: eventDetail }));
  }


  /* function to join variant combination string
     e.g generateVariantCombinationClass(xlspacesm)
     result will be xl-sm ,
  */
  generateVariantCombinationClass(combination) {
    // Remove leading and trailing spaces
    let trimmedString = combination.trim();
    // Split the string into words
    let words = trimmedString.split(/\s+/);
    // Join the words with hyphen
    let resultString = words.join('-');

    return resultString;
  }

  // this function creates each filter category and its respective filters in the DOM
  buildListItem(filterItem) {
    const li = document.createElement('li');
    const button = document.createElement('button');

    button.type = 'button';
    button.classList.add('text-primary', 'hover:text-blue-800', 'flex', 'gap-2', 'justify-content-start', 'items-center', 'group', 'capitalize', 'hover:underline');
    button.id = 'options-menu';
    button.setAttribute('aria-expanded', 'false');
    button.setAttribute('data-action', 'click->variant-list-section#filterCheckboxToggleHandler');
    button.textContent = filterItem.optionKey;

    if (filterItem.optionValues.length > 0) {
      const span = document.createElement('span');
      span.classList.add('mt-1');
      const svg = this.createDownArrowSVG();
      span.appendChild(svg);
      button.appendChild(span);

      const toggleableDiv = document.createElement('div');
      toggleableDiv.classList.add('hidden', 'absolute', 'z-50', 'w-50', 'top-10', 'bg-white', 'shadow-lg', 'origin-top-right', 'rounded-xl', 'ring-1', 'ring-black', 'ring-opacity-5', 'select-none');
      toggleableDiv.setAttribute('data-variant-list-section-target', 'toggleable');

      const optionValuesDiv = document.createElement('div');
      optionValuesDiv.classList.add('py-1');
      optionValuesDiv.setAttribute('role', 'menu');
      optionValuesDiv.setAttribute('aria-orientation', 'vertical');

      const existingPrefixes = new Set();
      const prefix = filterItem.optionKey.toLowerCase();

      if (!existingPrefixes.has(prefix)) {
        // Add the prefix to the set to ensure uniqueness
        existingPrefixes.add(prefix);

        filterItem.optionValues.forEach(optionValue => {
          //first: create checkbox container wrapping all the checkbox content
          const generatedCombination = this.generateVariantCombinationClass(optionValue);
          const filterCheckboxContainer = document.createElement('div');
          filterCheckboxContainer.classList.add('mb-[0.125rem]', 'block', 'min-h-[1.5rem]', 'px-[1rem]', 'py-[0.7rem]', `${prefix}-${generatedCombination}-${filterItem.sectionId}`, 'hover:cursor-pointer');
          filterCheckboxContainer.setAttribute('data-section-id', filterItem.sectionId);
          //second: create filter checkbox input element
          const filterCheckboxInput = document.createElement('input');
          filterCheckboxInput.classList.add('mr-2', 'rounded', 'focus:outline:none', 'bg-black', 'border-gray-300', 'select-none');
          filterCheckboxInput.type = 'checkbox';
          filterCheckboxInput.value = optionValue;
          filterCheckboxInput.id = `checkbox_${optionValue}`;
          //third: create label for filter checkbox
          const filterCheckboxLabel = document.createElement('label');
          filterCheckboxLabel.innerText = optionValue;
          filterCheckboxLabel.htmlFor = `checkbox_${optionValue}`;
          filterCheckboxLabel.classList.add('inline-block', 'pl-[0.15rem]', 'hover:cursor-pointer', 'select-none');
          //fourth: prepend filterCheckboxInput into filterCheckboxLabel
          filterCheckboxLabel.prepend(filterCheckboxInput);
          //fifth: append filterCheckboxLabel into filterCheckboxContainer
          filterCheckboxContainer.appendChild(filterCheckboxLabel);

          filterCheckboxContainer.setAttribute('data-variant-list-section-target', 'filterOptionCheckbox');
          filterCheckboxLabel.setAttribute('data-action', 'click->variant-list-section#filterOptionCheckboxHandler');

          optionValuesDiv.appendChild(filterCheckboxContainer);
        });

        toggleableDiv.appendChild(optionValuesDiv);
        li.appendChild(toggleableDiv);
      }
    }

    li.appendChild(button);
    return li;
  }

  //function that toggle each variant checkbox w.r.t classes on both filter and variant
  filterOptionCheckboxHandler(event) {
    // Extract checkbox state from the event.
    let checkbox;
    if (event.target.matches('label')) {
      checkbox = event.target.querySelector("input[type=checkbox]").checked;
    } else {
      checkbox = event.target.checked;
    }

    let checkboxChecked = checkbox;
    let sectionId = event.currentTarget.parentElement.getAttribute('data-section-id');
    let filterValue = event.target.closest('div').querySelector('input').value;
    let fields = document.querySelectorAll('.fields > div');
    let selectedCategory = event.currentTarget.parentElement.parentElement.parentElement.nextSibling.innerText.toLowerCase();

    // Find the index of the matched section in optionsFilterArray.
    let isMatchedSection = this.optionsFilterArray.findIndex(option => option.sectionId == sectionId);

    // Iterate through variant list items and update checkboxes based on conditions.
    fields.forEach((listItem) => {
      let classes = listItem.classList;
      let listItemValue = listItem.querySelector('.variant_title').innerText;
      let variantListItemCheckbox = listItem.querySelector('input[type="checkbox"]');

      if (classes.contains(`${selectedCategory}-${filterValue}-${sectionId}`) && listItemValue.includes(filterValue) && isMatchedSection !== -1) {
        variantListItemCheckbox.checked = checkboxChecked;
      }
    });

    // Update the selected filters state.
    this.updateSelectedFilters();
  }

  //re-usable function to update selected filters array
  updateSelectedFilters() {
    this.selectedFilterOptions = [];

    this.filterOptionCheckboxTargets.forEach(filterCheckbox => {
        // Clear the selectedFilterOptions array
        const checkbox = filterCheckbox.querySelector('input[type="checkbox"]');
        if (checkbox && checkbox.checked) {
            const checkboxValue = checkbox.value;
            this.selectedFilterOptions.push(checkboxValue);
        }
    });
    this.updateSelectedVariants();
  }

  //re-usable function to update state of selected variant items
  updateSelectedVariants() {
    this.selectedVariantItems = [];
    const nested_forms_fields = document.querySelectorAll('.nested-form-fields');
    nested_forms_fields.forEach(formField => {
      const isItemChecked = formField.querySelector('input[type=checkbox]').checked;
      if(isItemChecked){
        const listId = parseInt(formField.dataset.listId);
        const selectedVariant = this.variantsList.find(variant => variant.listId === listId);
        this.selectedVariantItems.push({...selectedVariant});
      }
    })
    // TO DO; Bulk actions functionality
    // const bulkActionsMenuParent = $('.bulk-actions-menu').parent();
    // bulkActionsMenuParent.toggleClass('hidden', this.selectedVariantItems.length === 0);

  }

  // Call the function to create the arrow down SVG for filters
  createDownArrowSVG() {
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("data-action", "click->variant-list-section#yourClickHandler");
    svg.setAttribute("data-target", "variant-list-section.svg");

    this.setAttributes(svg, {
      fill: "#38128A",
      height: "12px",
      width: "12px",
      version: "1.1",
      id: "Layer_1",
      xmlns: "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      viewBox: "0 0 330 330",
      "xml:space": "preserve",
      stroke: "#38128A",
      strokeWidth: "3"
    });

    svg.classList.add("group-hover:fill-blue-800");

    const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    this.setAttributes(path, {
      id: "XMLID_225_",
      d:
        "M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z",
    });

    svg.appendChild(path);

    return svg;
  }

  setAttributes(element, attributes) {
    for (const key in attributes) {
      element.setAttribute(key, attributes[key]);
    }
  }

  // Generate variantsList from the cartesianCombinations
  findBestPerfectMatch(cartesianCombinations, oldList) {
    if (cartesianCombinations.length < 1) {

      return []
    }

    // Create copy for old list
    let oldListMap = Object.create(oldList)
    let alreadyMatchedList = []
    let newList = []

    cartesianCombinations.forEach(combination => {
      let bestMatch = null
      let bestScore = 0
      let options;

      oldListMap.forEach(oldItem => {
        const score = this.calculateMatchScore(combination, oldItem.variantCombinations);

        if (score > bestScore) {
          bestScore = score
          bestMatch = oldItem
        }
      })

      if (bestMatch && !alreadyMatchedList.includes(bestMatch)) {

        alreadyMatchedList.push(bestMatch)
        const { available, barcode, listId, price, sku, id, archived, newArchived, avatar_url } = bestMatch
        // Assume optionsObject is declared and assigned somewhere in your code
        options = this.optionsList ? JSON.stringify(this.optionsList) : null;

        newList.push({ variantCombinations: combination, available, barcode, listId, price, sku, options, id, archived, newArchived, avatar_url })
      } else {
        if (combination.length > 0) {
          newList.push({
            variantCombinations: combination,
            price: $('.product-price  input').val() || 0.0,
            available: 0,
            sku: '',
            barcode: '',
            listId: this.sixDigitRandomID(),
            options: JSON.stringify(this.optionsList),
            id: '',
            archived: false,
            newArchived: false,
            avatar_url: ''
          })
        }
      }
    })

    return newList
  }

  // Calculate the matching score
  calculateMatchScore(newCombinations, oldCombinations) {
    let score = 0;

    newCombinations.forEach(newValue => {
      oldCombinations.forEach(oldValue => {
        if (newValue.includes(oldValue) || oldValue.includes(newValue)) {
          score++;
        }
      })
    })

    return score;
  }

  // Update the this.variantsList when user changed variant items fields
  // i.e price, available, on holdReady, sku and barcode
  variantLineItemHandler(event) {
    const { fieldName, listId } = event.target.dataset
    const value = event.target.value

    this.variantsList = this.variantsList.map(variant => {
      if (variant.listId == listId) {
        variant[fieldName] = value
      }
      return variant
    })
    // Dispatch a custom event to handle changes in the variants list in detect_product_changer
    window.dispatchEvent(new CustomEvent("detectChangesInVariantList", { detail: { variantsList: this.variantsList } }));
  }

  // This function formats the price input to a float with one decimal place
  formatPrice(event) {
    const input = event.target;
    let value = parseFloat(input.value);

    // Check if the value is a number
    if (!isNaN(value)) {
      // Format the value to a float with one decimal place
      input.value = value.toFixed(1);
    } else {
      input.value = '0.0';
    }
  }

  ensureInteger(event) {
    const input = event.target;
    const value = input.value.replace(/[^0-9-]/g, '');
    input.value = isNaN(parseInt(value)) ? '' : parseInt(value, 10).toString();
  }

  // Generate a Cartesian combinations from this.optionsList
  cartesianVariants(optionsList) {
    if(optionsList.length < 1){
      return []
    }
    return optionsList.reduce((accumulator, currentValue) => {
      if (currentValue.optionValues.length < 1) {
        return accumulator
      }

      return accumulator.flatMap((acc_item) => currentValue.optionValues.map((value) => [...acc_item, value]))
    }, [[]])
  }

  generateOptionsList() {
    let optionsList = [];
    let order = 1;
    // Iterate through variantItemSectionTargets
    this.variantItemSectionTargets.forEach((target) => {
      let optionObject = {
        sectionId: target.dataset.id,
        optionKey: '',
        optionValues: [],
        order: order,
      };

      let relatedOptionValueTargets = target.querySelectorAll(`.variant_input`);
      // Iterate through the found optionValueTargets
      relatedOptionValueTargets.forEach((field) => {
        if (field.value.trim().length !== 0 && field.dataset.sectionId == optionObject.sectionId) {
          optionObject.optionKey = field.dataset.optionKey;
          optionObject.order = field.closest('.variant-item-section').dataset.order
          optionObject.optionValues.push(field.value);
        }
      });

      // Check if optionObject is valid
      if (optionObject.optionKey.length !== 0 && optionObject.optionValues.length > 0) {
        optionsList.push(optionObject);
        order++;
      }
    });

    // Update this.optionsList with new data
    this.optionsList = optionsList;

    if (this.optionsList.length < 1) {
      this.variantsList = []
    }
    window.dispatchEvent(new CustomEvent("change",{detail: { variantOptionsList: this.optionsList, variantOptionSectionsCount: this.variantItemSectionTargets.length }}))
    // Return the updated this.optionsList
    return this.optionsList;
  }

  //Generate filters list from each Variant Option Section
  generateFiltersList() {
    this.optionsFilterArray = [
    {
      sectionId:'',
      optionKey: "All",
      optionValues: []
    },
    {
      sectionId:'',
      optionKey: "None",
      optionValues: []
    }]

    this.variantItemSectionTargets.forEach((target, index) => {
      let filterObject = {
        sectionId:target.dataset.id,
        optionKey: '',
        optionValues: []
      }
      this.optionValueTargets.forEach(field => {
        if (field.value.trim().length !== 0 && field.dataset.sectionId === target.dataset.id) {
          filterObject.optionKey = field.dataset.optionKey

          const existingKeyIndex = this.optionsFilterArray.findIndex(filterItem => filterItem.optionKey === field.dataset.optionKey);
            if (existingKeyIndex !== -1) {
                // If the optionKey already exists, update the existing entry's optionValues
                const nonEmptyValues = this.optionValueTargets
                    .filter(valueField => valueField.dataset.optionKey === field.dataset.optionKey && valueField.value !== '')
                    .map(valueField => valueField.value);

                // Update existing entry's optionValues
                this.optionsFilterArray[existingKeyIndex].optionValues = nonEmptyValues;
            }
            else{
              filterObject.optionValues.push(field.value)
              this.optionsFilterArray.push(filterObject)
            }
        }
      });
    });
  }

  /* Adds a unique class to a variant list item for efficient filtering based on selected options
    *and in necessarily in each variant combination format
    *e.g variantCombination:['xl','red', 'rubber'], classes generated will be
    *size-xl-123, color-red-345, material-rubber-678
  */
  assignUniqueClassesToVariants(nested_form, variant , filterOptionsArray) {
    //sets to keep record of already added key or combination
    const existingKeys = new Set();
    const existingCombinations = new Set();

    const sectionAndOptionKeys = filterOptionsArray.slice(2).map(filter => ({ sectionId: filter.sectionId, optionKey: filter.optionKey }));
    const latestOptionKey = sectionAndOptionKeys[sectionAndOptionKeys.length - 1];

    const matchingFilter = filterOptionsArray.find((filter) => filter.optionKey === latestOptionKey.optionKey);
    if (matchingFilter) {
      // Check if the key has already been added to a combination
      if (existingKeys.has(matchingFilter.optionKey.toLowerCase())) {
        return; // Skip this variant if the key is already used in another combination
      }
      variant.variantCombinations.forEach(combination => {
        sectionAndOptionKeys.forEach(option => {
          const prefix = option.optionKey.toLowerCase();
          const generatedCombinationForClass = this.generateVariantCombinationClass(combination)
          const classToAdd = `${prefix}-${generatedCombinationForClass}-${option.sectionId}`;
          // Check if the key has already been added to a combination
          if (existingKeys.has(prefix)) {
              return; // Skip this variant if the key is already used in another combination
          }
          // Check if the combination already exists on the nested_form
          if (existingCombinations.has(generatedCombinationForClass)) {
                return; // Skip this variant if the combination already exists
          }
          // Add the key to existingKey to ensure it's not used again
          existingKeys.add(prefix);
          // Add the class to the set to ensure uniqueness
          existingCombinations.add(generatedCombinationForClass);
          // Add the specific class to nested_form
          nested_form.classList.add(classToAdd);
          });
      })
  }
  }

  // Attach Variant Item Layout
  assignValuesToVariantListItem(nested_form, variantListItems) {
    nested_form.setAttribute('data-list-id', variantListItems[0].listId);
    variantListItems.forEach(object => {
      const { fieldName, value, listId } = object
      const productTitle = document.querySelector("#product_title").value
      let element = nested_form.querySelector(`.variant_${fieldName}`)
      const currentVariant =  this.variantsList.find(variant => variant.listId === listId);
      const currentVariantId = variantListItems.find(variant => variant.fieldName === 'id').value
      if (fieldName.includes("title")) {
        element.textContent = value
        element.href = `/admin/resources/variants/${currentVariantId}/edit`
        this.appendBadgeElement(element, currentVariantId, value)
      } else if (fieldName.includes("avatar")) {
        if (value) {
          $(element).attr('src', value)
        }
      } else if (fieldName.includes("sku") && !currentVariant.sku ) {
        const SKU = this.generateSKU(productTitle, currentVariant)
        element.value = SKU
      } else {
        element.value = value
      }
      element.dataset.listId = listId
      element.dataset.fieldName = fieldName
    })
  }

  appendBadgeElement(parentElement, id, value) {
    // Check if the variant id exists
    if (!id ) {
      // Create and configure a new span element
      const span = this.createSpanElement('text-primary font-medium overflow-auto break-words my-auto variant_title', value);
      // Replace the parent element with the new span
      parentElement.parentNode.replaceChild(span, parentElement);
      // Create a badge for new variant
      const badgeSpan = this.createSpanElement('inline-block whitespace-nowrap rounded-full bg-primary-100 px-[0.65em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-[0.75em] mx-2 leading-none text-primary-700 dark:bg-slate-900 dark:text-primary-500', i18n.t('variants.new'));
      // Append the badge to the new span
      span.appendChild(badgeSpan);
    }
  }

  createSpanElement(className, textContent) {
    const span = document.createElement('span');
    span.className = className;
    span.textContent = textContent;
    return span;
  }

  //this function generate SKU
  generateSKU(productTitle, variant) {
    const productTitleLetters = productTitle.split(" ").map(word => word.charAt(0).toUpperCase()).join("");
    const variantIdentifiers = variant.variantCombinations.map(word =>  word.charAt(0).toUpperCase()).join(""); /// ["Small"."Green", "Cotton"] => SGC
    const variantSKU = `${productTitleLetters}-${variant.listId}-${variantIdentifiers}`;
    return variantSKU;
  }

  addMoreOptionLinkHandler() {
    const addAnother = document.querySelector(`.add-another-option`);
    const isVariantCountBelowLimit = this.variantItemSectionTargets.length < 3;

    this.addOptionTarget.classList.toggle('hidden', !isVariantCountBelowLimit);
    addAnother.classList.toggle('border-b', this.variantItemSectionTargets.length > 0);
  }

  //function call when add new option is clicked
  addNewOptionLayout(event) {
    let variantOptionsLayout = event.target.dataset.variantOptionsLayout;
    let parser = new DOMParser();
    let variantOptionsLayoutToHtml = parser.parseFromString(variantOptionsLayout, 'text/html');
    let variantItemSection = variantOptionsLayoutToHtml.body.querySelector('.variant-item-section');
    if (variantItemSection) {
      this.variantOptionsLayoutTarget.appendChild(variantItemSection);
      //add order attribute for new variant item section
      this.variantOptionsLayoutTarget.querySelectorAll('.variant-item-section').forEach((section, index) => {
        if(!section.dataset.order){
          section.dataset.order = index+1;
        };
      })
      this.addMoreOptionLinkHandler();
      this.openSelect2OnInitialization(variantItemSection);
      // dispatch this function to trigger detectVariantOptionChanges
      if (this.variantItemSectionTargets.length <= 3) {
        const eventDetail = { variantOptionsList: this.optionsList, variantOptionSectionsCount: this.variantItemSectionTargets.length };
        window.dispatchEvent(new CustomEvent("detectVariantOptionChanges", { detail: eventDetail }));
      }

    } else {
      console.error('variant-item-section not found in the parsed HTML.');
    }
  }

  openSelect2OnInitialization(variantOptionsHtml) {
    let id = this.sixDigitRandomID();
    const newSelect = $(variantOptionsHtml).find('select');

    // Setting a unique ID for the new select
    newSelect.attr('id', id);
    // Getting the values of already selected options
    const currentOptionListSelector = this.currentOptionListSelector
    const allSelectedOptionsListSelectors = this.optionsListAllSelectors

    // Setting the selected option in the new select tag
    newSelect.val(currentOptionListSelector);
    self = this
    this.inputDefaultPlaceholder(currentOptionListSelector, $(this.variantItemSectionTarget).find(".variant_input")[0])

    // Initialize Select2
    $(`#${id}`).select2({
      minimumResultsForSearch: -1,
    }).on('change', function() {
      const context = this.closest('.variant-item-section');
      const optionContext = context.querySelector('.optionContext');
      const errorSpan = context.querySelector('.errorSpan');
      const selectedValues = allSelectedOptionsListSelectors;
      self.inputDefaultPlaceholder(this.value, $(this.closest('.variant-item-section')).find(".variant_input")[0])
      optionContext.textContent = this.value; // setting option value
      // Check if the selected option has already been selected
      if (selectedValues.includes(this.value) && this.value !== currentOptionListSelector) {
        $(this).siblings('.select2-container')
                         .find('.select2-selection')
                         .css('background-color', '#FEE9E9');
        // Display error message
        const capitalizedValue = this.value.charAt(0).toUpperCase() + this.value.slice(1);
        $(errorSpan).siblings().removeClass('hidden');
        errorSpan.textContent = `"${capitalizedValue}".`
      } else {
        // Clear the error message if the selected option is unique
        let siblings = $(errorSpan).siblings()
        if (!siblings.hasClass('hidden')) {
          siblings.addClass('hidden');
        }
        errorSpan.textContent = '';
        $(this).siblings('.select2')
                         .find('.select2-selection')
                         .css('background-color', '#F8F8F8');
      }
    });
    $(`#${id}`).trigger('change');

    // Open Select2 dropdown
    $(`#${id}`).select2('open');
  }

  inputDefaultPlaceholder (newOptionValue, Input) {
    switch (newOptionValue) {
      case "color":
        Input.placeholder = i18n.t('variants.black');
        break;
      case "material":
        Input.placeholder = i18n.t('variants.rubber');
        break;
      case "style":
        Input.placeholder = i18n.t('variants.classic');
        break;
      case "size":
        Input.placeholder = i18n.t('variants.medium');
        break;
      default:
        Input.placeholder = i18n.t('variants.add_another_value');
    }
  }

  variantSectionRemoveHandler(event) {
    const productId = document.querySelector('div[data-record-id]').getAttribute('data-record-id');
    setTimeout(() => {
      event.target.closest('.variant-item-section').remove();
      this.addMoreOptionLinkHandler()
      this.variantListManagement()
      const bulkActionsMenuParent = $('.bulk-actions-menu').parent();
      bulkActionsMenuParent.toggleClass('hidden', this.variantsList.length === 0);

      if (this.variantsList.length === 0) {
        this.selectAllVariantsTarget.checked = false;
        Controller.deleteAllVariantOption = true;
        if (Controller.deleteAllVariantOption) {
          $.ajax({
            url: `/admin/delete_variants/${productId}`,
            type: 'DELETE',
            success: function(response) {
              if (response.status == 200) {
                Controller.deleteAllVariantOption = false;
              }
            },
            error: function(xhr, status, error) {console.error("Error Variants Options:", error)}
          });
        }
      }
    }, 1000)
  }

  deleteAnOptionFieldHandler() {
    setTimeout(() => {
      this.variantListManagement()
    }, 500)
  }

  // A unique random ID for each Variant Item Layout
  sixDigitRandomID() {
    return Math.floor(100000 + Math.random() * 900000)
  }

  modifyVariantObject(event) {
    const dataListId = event.target.dataset.listId;
    if (this.variantsList.length > 0) {
      const editableVariantModal = document.querySelector('.editable-variant-modal-' + dataListId);
      const toggleVariantModal = document.querySelector('.toggle-variant-modal');
      const variantFieldSection = document.querySelector(`.variant-field-section`);
      const currentVariantList = document.querySelector(`.nested-form-fields[data-list-id="${dataListId}"]`);

      const variantListData = {
        variantCombinations: [],
        available: null,
        barcode: null,
        listId: null,
        price: null,
        sku: null,
        options: null,
        id: null,
        archived: null,
        newArchived: null,
        avatar_url: null
    };

    // Retrieving values from currentVariantList
    if (currentVariantList) {
        try {
            variantListData.available = currentVariantList.querySelector('.variant_available').value;
            variantListData.barcode = currentVariantList.querySelector('.variant_barcode').value;
            variantListData.listId = parseInt(currentVariantList.getAttribute('data-list-id'));
            variantListData.price = currentVariantList.querySelector('.variant_price').value;
            variantListData.sku = currentVariantList.querySelector('.variant_sku').value;
            variantListData.options = currentVariantList.querySelector('.variant_options').value;
            variantListData.id = parseInt(currentVariantList.querySelector('.variant_id').value);
            variantListData.archived = currentVariantList.querySelector('.variant_archived').value === 'true';
            variantListData.newArchived = currentVariantList.querySelector('.variant_newArchived').value === 'true';
            variantListData.avatar_url = currentVariantList.querySelector('.variant_avatar').getAttribute('src');
        } catch (error) {
            console.error("Error retrieving variant data:", error);
        }
    }

    // Parsing options
    if (variantListData.options) {
        try {
            const options = JSON.parse(variantListData.options);
            options.forEach(option => {
                variantListData.variantCombinations.push(option.optionValues[0]);
            });
        } catch (error) {
            console.error("Error parsing options:", error);
        }
    }
      let saveVariantModal = document.querySelector('.save-variant-modal');
      if (variantFieldSection.classList.contains('hidden')) {
        variantFieldSection.classList.remove('hidden')
        this.rememberTarget.value = '1';
        this.rememberTarget.checked = true;
      }

      // remove modifyVariantObject using save-variant-modal class if it exists
      this.variantsList.forEach((object) => {
        const { listId } = object;
        if (saveVariantModal.classList.contains('save-variant-modal-' + listId)) {
          saveVariantModal.classList.remove('save-variant-modal-' + listId);
        }
      })

      saveVariantModal.classList.add('save-variant-modal-' + dataListId);
      saveVariantModal.setAttribute('data-list-id', dataListId);
      Object.keys(variantListData).forEach((key) => {
        if (["id", "archived", "newArchived"].some(str => key.includes(str))) {
          return;
        }

        if (!key.includes("listId") && !key.includes("remember")) {
          let element = editableVariantModal.querySelector(`.modify-variant-${key}`)
          let value = variantListData[key];

          if (key.includes("variantCombinations")) {
            element.textContent = value.join(' / ')
          } else if (key !== "avatar_url" && !key.includes('options')) {
            element.value = value;
          }
        }
      });

      toggleVariantModal.click();
    }
  }

  saveVariantObject(event) {
    const dataListId = event.target.dataset.listId;
    const currentEditableVariant = document.querySelector(`.editable-variant-modal-${dataListId}`);
    const inputElements = currentEditableVariant.querySelectorAll('input');
    const variantsListSection = document.querySelector("[data-variant-list-section-target=variantsListSection]");
    const currentVariantItem = variantsListSection.querySelector(`[data-list-id="${dataListId}"]`);
    const currentVariantList = document.querySelector(`.nested-form-fields[data-list-id="${dataListId}"]`);
    const inputValues = {};

    inputElements.forEach((input) => {
      inputValues[input.name] = input.value;
    });

    // Update the currentVariantItem with inputValues
    for (const key in inputValues) {
      if (inputValues.hasOwnProperty(key)) {
        currentVariantItem[key] = inputValues[key];
      }
    }

    // Update the input values in the currentVariantList
    const inputElementsInList = currentVariantList.querySelectorAll('input');
    inputElementsInList.forEach((input) => {
      const name = input.getAttribute('data-field-name');
      if (inputValues.hasOwnProperty(name)) {
        input.value = inputValues[name];
      }
    })


    const isChecked = this.rememberTarget.checked

    if (!isChecked) {
      if (currentVariantItem) {
        this.checkIsValueAssignedSomewhere(currentVariantItem, currentVariantList, dataListId)
      }
    }

    event.target.previousElementSibling.click();
  }

  handleRememberChange(){
    const isChecked = this.rememberTarget.checked;
    let rememberValue = this.rememberTarget.value
    const variantFieldSection = document.querySelector(`.variant-field-section`);

    if (isChecked) {
      rememberValue = '1'
      variantFieldSection.classList.remove('hidden')
    }
    else {
      rememberValue = '0'
      variantFieldSection.classList.add('hidden')
    }
  }

  removeVariantItemFromList(event){
    const dataListId = event.target.dataset.listId;
    const currentVariantItem = this.variantsList.find(variantList => variantList.listId == dataListId);
    const currentVariantFieldItem = event.target.closest('.nested-form-fields')

    if (currentVariantItem) {
      this.checkIsValueAssignedSomewhere(currentVariantItem, currentVariantFieldItem, dataListId)
    }
  }

  checkIsValueAssignedSomewhere(currentVariantItem, currentVariantFieldItem, dataListId) {
    const values = currentVariantItem.variantCombinations;

    // Check if all values in currentVariantItem's variantCombinations match any values in other items
    const isValueAssignedSomewhere = values.some(value =>
      this.variantsList.some(variant =>
        variant.listId != dataListId &&
        variant.variantCombinations.includes(value)
      )
    );

    if (isValueAssignedSomewhere || this.storeCartesianCombinationsList(this.optionsList).length >= 1) {
      this.manageCurrentVariantFieldItem(true, currentVariantFieldItem);
      // This function is used to show addMoreOption button
      //   this.addMoreOptionLinkHandler();
    }
  }

  // Undo styling of object fields
  undoVariantItemFromList(event) {
    const currentVariantFieldItem = event.target.closest('.nested-form-fields')
    this.manageCurrentVariantFieldItem(false, currentVariantFieldItem)
  }

  // Scroll back to list section
  closeModalAndReturnToOriginalPosition(){
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth"
      });
    }, 100);
  }

  // Toggle styling of object fields
  manageCurrentVariantFieldItem(canRemove, currentVariantFieldItem) {
    const listId = currentVariantFieldItem.dataset.listId;
    let selectedVariant = this.variantsList.find(variant => variant.listId == listId);
    const currentEditObject = currentVariantFieldItem.querySelector('.variant_edit_object');
    const currentEditTitle = currentVariantFieldItem.querySelector('.variant_title');
    const archivedInput = currentVariantFieldItem
                            .closest('.fields')
                            .querySelector('input[name*="[archived]"]');
    selectedVariant.newArchived = canRemove ? true : false;
    // Dispatch a custom event to handle changes in the variants list in detect_product_changer
    window.dispatchEvent(new CustomEvent("detectChangesInVariantList", { detail: { variantsList: this.variantsList } }));
    currentEditTitle.style.textDecoration = canRemove ? 'line-through' : 'none';

    const undoSection = currentEditObject.closest('.actions-section').querySelector('.variant_undo_section');
    const deleteSection = currentEditObject.closest('.actions-section').querySelector('.variant_delete_section');

    undoSection.classList.toggle('hidden', !canRemove);
    deleteSection.classList.toggle('hidden', canRemove);

    currentVariantFieldItem.querySelectorAll('.undo-input').forEach((selector) => {
      selector.style.backgroundColor = canRemove ? '#E5E5E5' : '';
      selector.style.pointerEvents = canRemove ? 'none' : 'auto';
      selector.readonly = canRemove;
    });

    currentVariantFieldItem.querySelectorAll('.undo-bg').forEach((selector) => {
      selector.style.backgroundColor = canRemove ? '#F1F2F3' : '';
    });
    currentVariantFieldItem.style.backgroundColor = canRemove ? '#F1F2F3' : '';

    archivedInput.value = canRemove ? '1' : '0';
  }

  //edit bulk prices for selected variants
  modifyBulkVariantsPrice() {
    if (this.selectedVariantItems.length > 0) {
      const toggleVariantModal = document.querySelector('.toggle-variant-modal');
      const variantFieldSection = document.querySelector(`.variant-field-section`);
      let saveVariantModal = document.querySelector('.save-variant-modal');

      if (variantFieldSection.classList.contains('hidden')) {
        variantFieldSection.classList.remove('hidden');
      }

      const modalVariantRememberCheck = document.querySelector('.variant-remember-section');
      const modalVariantFieldSection = document.querySelector('.variant-field-section');

      // Remove the variant-remember-check
      modalVariantRememberCheck.remove();
      // Clear the inner HTML of variant-field-section
      modalVariantFieldSection.innerHTML = "";

      // Iterate through selectedVariantItems and generate input fields and variantCombination lists
      this.selectedVariantItems.forEach((variant, index) => {
        // Create a new input field for the price
        const variantWrapper = document.createElement('div')
        variantWrapper.classList.add('flex', 'justify-between', 'px-2', 'py-2', 'border-b-2', 'border-gray-300')
        variantWrapper.setAttribute('data-list-id', variant.listId)
        const priceInput = document.createElement('input');
        priceInput.type = 'text';
        priceInput.value = variant.price; // Replace this with the actual property you want to display
        priceInput.name = `variant_price_${variant.listId}`; // Replace this with a meaningful name
        // Add any additional attributes or classes as needed

        // Create a new span for variantCombinations
        const variantCombinationSpan = document.createElement('span');
        variantCombinationSpan.textContent = variant.variantCombinations.join(' / ');
        // Append the input field and span to the variant-field-section
        variantWrapper.appendChild(priceInput)
        variantWrapper.appendChild(variantCombinationSpan)
        modalVariantFieldSection.appendChild(variantWrapper);
      });

      // Click the toggleVariantModal to open the modal
      toggleVariantModal.click();
    }
  }
}
// red blue black, cotton wares, Neck Front
