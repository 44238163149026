import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["nameField", "unique"];

  connect() {}

  nameUniqueFilter(event) {
    let request_url =
      this.nameFieldTarget.dataset.url +
      "?name=" +
      (event.target.value.toLowerCase() || "");

    this.nameUniqueApi(request_url);
  }

  nameUniqueApi(request_url) {
    fetch(request_url, {
      method: "get",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.exists) {
          this.uniqueTarget.classList.remove("hidden");
        } else {
          this.uniqueTarget.classList.add("hidden");
        }
      });
  }
}
