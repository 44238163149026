import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {}

  search(event) {
    let url = event.target.dataset.url + '?' + event.target.dataset.typeKey + '=' + event.target.value;
    this.sendRequest(url);
  }

  sort(event) {
    let url = event.currentTarget.dataset.url + '?order=' + event.currentTarget.dataset.orderType
    this.sendRequest(url);
  }

  sendRequest(url) {
    fetch(url, {
      method: 'get',
    }).then((response) => response.text());
  }
}
