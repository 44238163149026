import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["nestedFormContainer", "template"];
  static values = {
    wrapperSelector: {
      type: String,
      default: ".nested-form-wrapper",
    },
  };

  connect() {}

  add(event) {
    event.preventDefault();

    const content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime().toString()
    );
    const wrapper = document.createElement("div");
    wrapper.innerHTML = content;

    const currentSelect = wrapper.querySelector("select");
    const selectedValues = this.getSelectedValues();

    Array.from(currentSelect.options).forEach((option) => {
      if (selectedValues.includes(option.value)) {
        option.remove();
      }
    });

    this.nestedFormContainerTarget.insertAdjacentHTML("beforebegin", wrapper.innerHTML);
  }

  getSelectedValues() {
    const previousSelects = Array.from(this.element.querySelectorAll("select"));
    return previousSelects.map((select) => select.value);
  }

  remove(event) {
    event.preventDefault();

    const wrapper = event.target.closest(this.wrapperSelectorValue);
    wrapper.remove()
  }
}
