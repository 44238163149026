  import { Controller } from "@hotwired/stimulus";

  export default class extends Controller {
    static targets = ['variantInput', 'inventoryInput', 'warehouseInput'];

    connect() {
      this.handleInventoryLevel()
    }

    handleVariant() {
      this.handleInventoryLevel()
    }

    handleInventoryLevel() {
      const variant = this.variantInputTarget.value
      const warehouse = this.warehouseInputTarget.value

      if (!(variant && warehouse)) {
        this.inventoryInputTarget.disabled = true
      } else {
        this.inventoryInputTarget.disabled = false

        this.fetchInventoryLevels(variant, warehouse);
      }
    }

    async fetchInventoryLevels(variant, warehouse) {
      const response = await fetch(`/incoming_stocks/inventory_levels?variant_id=${variant}&warehouse_id=${warehouse}`);
      const data = await response.json();

      this.updateInventorySelectOptions(data);
    }

    updateInventorySelectOptions(data) {
      const inventorySelect = this.inventoryInputTarget
      inventorySelect.innerHTML = '';

      data.forEach(item => {
        const option = document.createElement('option');
        option.value = item.id;
        option.text = item.id;
        inventorySelect.appendChild(option);
      });
    }
  }
