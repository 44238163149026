import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["list"];

  toggle() {
    this.listTarget.classList.toggle("hidden");
  }

  connect() {
    document.addEventListener("click", this.closeDropdownOutside);
  }

  disconnect() {
    document.removeEventListener("click", this.closeDropdownOutside);
  }

  closeDropdownOutside = (event) => {
    if (!this.element.contains(event.target)) {
      this.listTarget.classList.add("hidden");
    }
  };
}
