import { Controller } from "@hotwired/stimulus";
import OrderItemsController from "./order_items_controller";
import AvoCustomInputsController from "./avo_custom_inputs_controller";

export default class extends Controller {
  static targets = ["available", "output", "flash", "result", "id", "flash_message", "message", "customMessage"];

  connect() { }

  warehouse_change(event) {
    var request_url = event.target.getAttribute('data-url') + "?inventory_id=" + event.target.getAttribute('data-id') + "&id=" + this.idTarget.value || "";
    fetch(request_url, {
      method: "get",
    }).then((response) => response.json())
      .then((response) => {
        if (response.status) {
          this.warehouse_flash_message(response, "red", "green")
        }
        else {
          this.warehouse_flash_message(response, "green", "red")
        }
      });
  }

  warehouse_flash_message(response, removeColor, addColor) {
    this.messageTarget.textContent = response.message
    this.flash_messageTarget.classList.remove(`bg-${removeColor}-500`, `border-${removeColor}-700`);
    this.flash_messageTarget.classList.add(`bg-${addColor}-400`, `border-${addColor}-700`);
    this.flash_messageTarget.removeAttribute("hidden");
    setTimeout(() => {
      this.flash_messageTarget.setAttribute("hidden", "");
    }, 5000);
  }

  inventory_change() {
    this.outputTarget.removeAttribute("hidden");
  }

  inventory_submit({ params }) {
    var request_url =
      params.url +
      "?id=" +
      params.id +
      "&available=" +
      this.availableTarget.value || "";
    fetch(request_url, {
      method: "get",
    }).then((response) => {
      this.outputTarget.setAttribute("hidden", "");
      const customMessage = this.customMessageTarget
      customMessage.textContent = params.message
      this.flashTarget.removeAttribute("hidden");
      setTimeout(() => {
        this.flashTarget.setAttribute("hidden", "");
      }, 5000);
    });
  }

  add_inventory({ params }) {
    var request_url = params.url + "?id=" + params.id;
    fetch(request_url)
      .then((res) => res.text())
      .then((response) => {
        response = JSON.parse(response);
        if (response.success) {
          const fragment = document
            .createRange()
            .createContextualFragment(response.content);

          this.resultTarget.appendChild(fragment);
        } else {
          let status = false
          let element = document.querySelector(".main-flash-container");
          element.innerHTML +=
            this.custom_flash_message().prototype.custom_flash_message(
              JSON.parse(response).message,
              element,
              status
            );
        }
      });
  }

  remove_inventory(event) {
    let id = event.target.getAttribute("data-id");
    let element = "destroy-" + id;
    let parentEle = document.querySelectorAll(
      `.sample-inventory-item [class$="${element}"]`
    );
    parentEle[0].setAttribute("value", true);
    event.target
      .closest(".new-inventory-level")
      .setAttribute("style", "display:none");
  }

  variant_form_modal({ params }) {
    fetch(params.url)
      .then((res) => res.text())
      .then((response) => {
        this.fetch_modal_response(response);
      });
  }

  inventory_form_modal({ params }) {
    fetch(params.url)
      .then((res) => res.text())
      .then((response) => {
        this.fetch_modal_response(response);
      });
  }

  fetch_modal_response(response) {
    response = JSON.parse(response);
    if (response.success == false) {
      let status = false
      let element = document.querySelector(".main-flash-container");
      element.innerHTML +=
        this.custom_flash_message().prototype.custom_flash_message(
          response.message,
          element,
          status
        );
    } else {
      const fragment = document
        .createRange()
        .createContextualFragment(response.content);
      let selector = document.querySelector(".modal-result-section");
      selector.innerHTML = "";
      selector.appendChild(fragment);
      document.querySelector(".open-modal").click();
      document.querySelector("body").style.top = "0px";

      setTimeout(() => {
        this.avo_custom_inputs_controller().prototype.connect();
      }, 200);
    }
  }

  avo_custom_inputs_controller() {
    return AvoCustomInputsController;
  }

  inventory_success(event) {
    this.close_modal_action(event);
  }

  inventory_error(event) {
    this.close_modal_action(event);
  }

  variant_success(event) {
    this.close_modal_action(event);
  }

  variant_error(event) {
    this.close_modal_action(event);
  }

  close_modal_action(event) {
    let [data, status, xhr] = event.detail;
    if (status.includes("Unprocessable Entity")) {
      let element = document.querySelector(".main-flash-container");
      element
        .querySelectorAll(".inventory-custom-flash")
        .forEach((el) => el.remove());
      JSON.parse(xhr.response).error.forEach((message) => {
        let status = false
        element.innerHTML +=
          this.custom_flash_message().prototype.custom_flash_message(
            message,
            element,
            status
          );
      });
      setTimeout(
        () =>
          element
            .querySelectorAll(".inventory-custom-flash")
            .forEach((el) => el.remove()),
        5000
      );
      return;
    }
    if (JSON.parse(xhr.response).error) return;

    if (xhr.responseURL?.includes("manage_inventory")) {
      document.getElementById("inventory-items-row").innerHTML = "";
      document.getElementById("inventory-items-row").innerHTML = JSON.parse(
        xhr.response
      ).content;
    }

    if (xhr.responseURL?.includes("manage_variant")) {
      document.getElementById("variant-items-row").innerHTML = "";
      document.getElementById("variant-items-row").innerHTML = JSON.parse(
        xhr.response
      ).content;
      document.getElementById("inventory-items-row").innerHTML = JSON.parse(
        xhr.response
      ).inventory_content;
    }
    document.querySelector(".product-variant-inventory-model").click();
  }

  custom_flash_message() {
    return OrderItemsController;
  }
}
